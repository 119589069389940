import React, { useEffect, useState } from 'react'
import { isBefore, isPast } from 'date-fns'
import {
  ActionButton,
  ActionButtonSkeleton,
  ProgressStep,
  ProgressSteps,
} from '@revolut/ui-kit'
import { CheckpointType, ProbationCheckpoints } from '@src/interfaces/probationReview'
import {
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import {
  getStartOfCheckpoint,
  getStepDescription,
  getStepState,
  getStepTitle,
} from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'
import { formatDate } from '@src/utils/format'
import { CheckpointStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CheckpointStepReviewers'
import { CommitteeStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CommitteeStepReviewers'
import { RecommendationStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/RecommendationStepReviewers'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FeatureFlags } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { createDraftGoal } from '@src/api/goals'
import { captureException } from '@sentry/react'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'

type Props = {
  data: EmployeeInterface
  checkpoints: ProbationCheckpoints
  endDate: string
  requests?: PerfReviewRequestFeedbackInterface[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
  performanceLink?: string
}

const progressStepTitle: { [key: string]: string } = {
  [ReviewCategory.Probation]: 'End of probation',
  [ReviewCategory.PIP_V2]: 'End of PIP',
}

export const ProbationLayoutTimeline = ({
  data,
  checkpoints,
  endDate,
  requests,
  employeeId,
  cycleId,
  reviewCategory,
  performanceLink,
}: Props) => {
  const [summaryCheckpoint, setSummaryCheckpoint] = useState<
    ReviewSummaryInterface | undefined
  >()
  const user = useSelector(selectUser)
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  const { navigateWithEntity } = useOrgEntity()
  const { data: contentTypes, isLoading: contentTypesLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const fetchSummary = async () => {
    const result = await getPerformanceReviewSummary(
      cycleId,
      employeeId,
      reviewCategory,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: 'line_manager',
              name: 'line_manager',
            },
            {
              id: 'functional_manager',
              name: 'functional_manager',
            },
          ],
        },
      ],
    )

    setSummaryCheckpoint(result.data)
  }

  useEffect(() => {
    fetchSummary()
  }, [])
  const timelineSteps = [
    ...checkpoints.checkpoints,
    ...(checkpoints.decision_checkpoints ? checkpoints.decision_checkpoints : []),
  ]
  let currentTimelineSteps = timelineSteps.filter(
    event =>
      getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline) === 'pending',
  )
  if (!currentTimelineSteps.length) {
    currentTimelineSteps = timelineSteps
      .filter(
        event => getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline),
        'default',
      )
      .slice(0, 1)
  }

  const formattedEndDate = formatDate(endDate, 'd MMMM yyyy')
  const endTitle = isPast(new Date(endDate))
    ? `Closed ${formattedEndDate}`
    : `Closing ${formattedEndDate}`

  const onClickAddGoals = async () => {
    if (reviewCategory === ReviewCategory.Probation && goalsEnabled) {
      try {
        setIsCreateGoalPending(true)
        const response = await createDraftGoal({
          owner: { id: data.id },
          content_type: contentTypes?.find(({ model }) => model === 'employees'),
          object_id: data.id,
        })
        navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
          reviewCycleId: cycleId,
          isNew: true,
        })
      } catch (err) {
        captureException(err)

        showStatusPopup({
          status: 'error',
          title: 'Failed to create goal',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      } finally {
        setIsCreateGoalPending(false)
      }
    } else {
      navigateTo(
        pathToUrl(
          reviewCategory === ReviewCategory.Probation
            ? ROUTES.FORMS.PROBATION_GOALS
            : ROUTES.FORMS.PIP.GOAL_SETTINGS,
          {
            employeeId,
            cycleId,
            id: cycleId,
          },
        ),
      )
    }
  }

  return (
    <>
      <ProgressSteps variant="vertical-compact">
        {timelineSteps.map(checkpoint => {
          const title = getStepTitle(checkpoint, reviewCategory)
          const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
          const state = getStepState(startOfCheckpoint, checkpoint.checkpoint_deadline)
          const description = getStepDescription(checkpoint)
          const isLineManager = user.id === data.line_manager?.id
          const canAddGoals =
            (reviewCategory === ReviewCategory.PIP_V2 || isLineManager) &&
            state === 'pending'

          return (
            <ProgressStep key={checkpoint.id} state={state}>
              <ProgressStep.Title>{title}</ProgressStep.Title>
              <ProgressStep.Description>{description}</ProgressStep.Description>
              {checkpoint.checkpoint_type === CheckpointType.Goals && canAddGoals && (
                <ProgressStep.Side>
                  {contentTypesLoading ? (
                    <ActionButtonSkeleton />
                  ) : (
                    <ActionButton
                      useIcon="Plus"
                      onClick={onClickAddGoals}
                      pending={isCreateGoalPending}
                    >
                      Add goals
                    </ActionButton>
                  )}
                </ProgressStep.Side>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Review && (
                <ProgressStep.Description>
                  <CheckpointStepReviewers
                    checkpoint={checkpoint}
                    requests={requests}
                    grades={summaryCheckpoint?.summary?.final_grades}
                    reviewCategory={reviewCategory}
                    performanceLink={performanceLink}
                  />
                </ProgressStep.Description>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Recommendation && (
                <ProgressStep.Description>
                  <RecommendationStepReviewers
                    checkpoint={checkpoint}
                    reviewCategory={reviewCategory}
                    cycleId={cycleId}
                    employeeId={employeeId}
                  />
                </ProgressStep.Description>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Decision && (
                <ProgressStep.Description>
                  <CommitteeStepReviewers
                    checkpoint={checkpoint}
                    reviewCategory={reviewCategory}
                    cycleId={cycleId}
                    employeeId={employeeId}
                  />
                </ProgressStep.Description>
              )}
            </ProgressStep>
          )
        })}

        <ProgressStep
          state={isBefore(new Date(endDate), new Date()) ? 'done' : 'default'}
        >
          <ProgressStep.Title>{progressStepTitle[reviewCategory]}</ProgressStep.Title>
          <ProgressStep.Description>{endTitle}</ProgressStep.Description>
        </ProgressStep>
      </ProgressSteps>
    </>
  )
}
