import React, { useRef } from 'react'
import {
  chain,
  createChain,
  Flex,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  KeeperGradeToString,
  KeeperQuestionInterface,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewInterface,
  ReviewSummaryDataInterface,
  ReviewViewDataInterface,
} from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceSummaryBarRaiserReviewerColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { BarRaiserBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserBanner'
import { Warning } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Cards'
import pluralize from 'pluralize'
import SummaryCommentsPopup from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryCommentsPopup'
import { relationToString } from '@src/features/Scorecard/constants'

interface Props {
  data: ReviewSummaryDataInterface | ReviewDataInterface
  completedReviews?: boolean
  hideBanner?: boolean
}

interface RecommendationQuestionReviewInterface {
  recommendation: ManagerRecommendationInterface
  type: RecommendationTypes
}

export interface RecommendationQuestionInterface extends KeeperQuestionInterface {
  reviews: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}

const PeerReviewsWithTooltip = ({
  reviews,
  questionIdx,
}: {
  reviews?: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}) => {
  const tooltip = useTooltip()

  if (!reviews?.length) {
    return <Text variant="secondary">-</Text>
  }

  return (
    <Text variant="caption" textDecoration="underline" {...tooltip.getAnchorProps()}>
      {chain('View', reviews.length)}
      <Tooltip {...tooltip.getTargetProps()} placement="top">
        <VStack>
          {reviews.map(review => {
            const questionValue =
              review.recommendation.keeper_test_section.questions[questionIdx].value
            const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
            return (
              <Flex key={review.recommendation.reviewer?.id}>
                {chain(
                  <Text variant="small" fontWeight="bold">
                    {gradeValue}
                  </Text>,
                  <Text variant="small">
                    {review.recommendation.reviewer?.full_name}
                  </Text>,
                )}
              </Flex>
            )
          })}
        </VStack>
      </Tooltip>
    </Text>
  )
}

const getRow: (
  completedReviews: boolean,
) => RowInterface<RecommendationQuestionInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: 'Questions',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const lmReview = data.reviews?.find(review => review.type === 'lm')
        const questionValue =
          lmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return lmReview ? gradeValue : '-'
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const fmReview = data.reviews?.find(review => review.type === 'fm')
        const questionValue =
          fmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return fmReview ? gradeValue : '-'
      },
      title: 'FM',
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const peerReviews = data.reviews?.filter(review => review.type === 'peer')
        const questionValue =
          peerReviews?.[0]?.recommendation.keeper_test_section.questions[data.questionIdx]
            .value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return completedReviews ? (
          <PeerReviewsWithTooltip reviews={peerReviews} questionIdx={data.questionIdx} />
        ) : (
          gradeValue
        )
      },
      title: 'Peer',
      width: 60,
    },
  ],
})

const getRecommendations = (
  data: ReviewSummaryDataInterface | ReviewViewDataInterface,
) => {
  const lmRecommendation = data.line_manager_extra_section
  const fmRecommendation = data.functional_manager_extra_section
  const peerRecommendations = data.peer_extra_sections

  if (!lmRecommendation && !fmRecommendation && !peerRecommendations?.length) {
    return null
  }

  return [
    ...(lmRecommendation
      ? [
          {
            recommendation: lmRecommendation,
            type: RecommendationTypes.LM,
          },
        ]
      : []),
    ...(fmRecommendation
      ? [
          {
            recommendation: fmRecommendation,
            type: RecommendationTypes.FM,
          },
        ]
      : []),
    ...(peerRecommendations
      ? [
          ...peerRecommendations.map(item => ({
            recommendation: item,
            type: RecommendationTypes.PEER,
          })),
        ]
      : []),
  ]
}

export const BarRaiserSummary = ({
  data,
  completedReviews = false,
  hideBanner,
}: Props) => {
  const barRaiserRef = useRef<HTMLButtonElement>(null)
  const recommendations = getRecommendations(data)

  if (!recommendations?.length) {
    return null
  }

  const messageChain = createChain(' ')
  const relationString = (relation: ReviewerRelation) =>
    `(${relationToString(relation, true)})`

  const getJustification = (
    recommendation: ManagerRecommendationInterface,
    relation: ReviewerRelation,
  ) => {
    if (
      !recommendation?.employee_project_performance.justification ||
      !recommendation?.reviewer
    ) {
      return null
    }

    return {
      value: recommendation.employee_project_performance.justification,
      review: {
        ...recommendation,
        reviewer_relation: relation,
      } as unknown as ReviewInterface,
    }
  }

  const fmJustification = data.functional_manager_extra_section
    ? getJustification(
        data.functional_manager_extra_section,
        ReviewerRelation.FunctionalManager,
      )
    : null
  const lmJustification = data.line_manager_extra_section
    ? getJustification(data.line_manager_extra_section, ReviewerRelation.LineManager)
    : null

  const justifications = [lmJustification, fmJustification].filter(Boolean)

  const tableData = recommendations[0].recommendation.keeper_test_section.questions.map(
    (question, idx) => ({
      ...question,
      reviews: recommendations,
      questionIdx: idx,
    }),
  )

  return (
    <VStack space="s-16" ref={barRaiserRef}>
      <Text variant="h6" color={Token.color.greyTone50}>
        Bar raiser
      </Text>
      {!hideBanner && <BarRaiserBanner />}
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable
          name={TableNames.PerformanceSummaryBarRaiser}
          row={getRow(completedReviews)}
          count={tableData.length}
          data={tableData}
          hideCountAndButtonSection
        />
      </Flex>
      {justifications.length ? (
        <Warning multiline>
          {messageChain(
            justifications[0].review.reviewer.display_name,
            relationString(justifications[0].review.reviewer_relation),
            justifications[1] && 'and',
            justifications[1] && justifications[1].review.reviewer.display_name,
            justifications[1] &&
              relationString(justifications[1].review.reviewer_relation),
            'added',
            pluralize('justification', justifications.length),
          )}
          <SummaryCommentsPopup
            data={justifications}
            title="Grade justification"
            subtitle=""
          />
        </Warning>
      ) : null}
    </VStack>
  )
}
