import {
  differenceInDays,
  formatDistanceToNow,
  isFuture,
  isPast,
  isTomorrow,
  isYesterday,
} from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import { Token, Text } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { SingleTimelineEventInterface } from '@src/interfaces/performance'
import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'

export const getTimelineStepState = (
  startDate: string,
  endDate: string,
  category?: string,
) => {
  if (category === 'meeting') {
    return 'pending'
  }

  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getDateDistance = (date: string) => {
  if (!date) {
    return <>-</>
  }

  const dayDiff = differenceInDays(new Date(date), new Date())
  return (
    <Text color={dayDiff <= 3 ? Token.color.orange : Token.color.greyTone50}>
      {`in ${formatDistanceToNow(new Date(date))}`}
    </Text>
  )
}

export const getTimelineStepDescription = (event: SingleTimelineEventInterface) => {
  if (event.category === 'meeting') {
    let dayReference
    if (isSameDay(new Date(), new Date(event.end_period))) {
      dayReference = 'today'
    } else if (isYesterday(new Date(event.end_period))) {
      dayReference = 'yesterday'
    } else if (isTomorrow(new Date(event.end_period))) {
      dayReference = 'tomorrow'
    }

    return (
      <>
        <Text use="p">
          {`${formatDate(event.start_period, 'EEE, d MMM yyyy')}`}
          {dayReference && <Text color={Token.color.orange}>{` - ${dayReference}`}</Text>}
        </Text>
      </>
    )
  }

  if (event.category === 'review_results') {
    if (isPast(new Date(event.start_period))) {
      return (
        <Text>{`Closed ${formatDate(
          event.start_period,
          'd MMMM yyyy',
        )} - ${formatDistanceToNow(new Date(event.start_period), {
          addSuffix: true,
        })}`}</Text>
      )
    }
    return (
      <>
        <Text use="p">
          {`Closes ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.start_period)}
        </Text>
      </>
    )
  }

  if (isPast(new Date(event.end_period))) {
    return (
      <Text>{`Closed ${formatDate(
        event.end_period,
        'd MMMM yyyy',
      )} - ${formatDistanceToNow(new Date(event.end_period), {
        addSuffix: true,
      })}`}</Text>
    )
  }

  if (isFuture(new Date(event.start_period))) {
    return (
      <Text>
        {`Opens ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
        {getDateDistance(event.start_period)}
      </Text>
    )
  }

  if (
    event.category === 'kpi' ||
    event.category === 'review' ||
    event.category === 'promotion'
  ) {
    return (
      <>
        <Text use="p">
          {`Closes ${formatDate(event.end_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.end_period)}
        </Text>
      </>
    )
  }

  return <>-</>
}

export const canViewProgressionComponent = (data: EmployeeInterface) => {
  const senioritySublevel = data.specialisation_seniority_sublevel?.sublevel
  const maxSublevelToSeeProgression = 2
  return senioritySublevel && senioritySublevel <= maxSublevelToSeeProgression
}
