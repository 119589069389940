import {
  TableWidget,
  MoreBar,
  Highlights,
  DataPoint,
  FilterButton,
  DataPointSkeleton,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { engagementSurveysTableRequests, useGetSurveysStats } from '@src/api/engagement'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  surveyFrequencyColumn,
  surveyNameColumn,
  surveyOwnerColumn,
  surveyRoundStatus,
  surveyTimelineColumn,
} from '@src/constants/columns/engagementSurveys'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  EngagementSurveyInterface,
  EngagementSurveyStatsInterface,
} from '@src/interfaces/engagement'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isHubAppPath } from '../helpers/isHubApp'

const ROW: RowInterface<EngagementSurveyInterface> = {
  linkToForm: ({ id }) => {
    const isHubApp = isHubAppPath()
    navigateTo(
      pathToUrl(ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DETAILS_OVERVIEW, {
        id,
      }),
    )
  },
  cells: [
    {
      ...surveyNameColumn,
      width: 300,
    },
    { ...surveyOwnerColumn, width: 150 },
    { ...surveyFrequencyColumn, width: 170 },
    { ...surveyTimelineColumn, width: 105 },
    { ...surveyRoundStatus, width: 70 },
  ],
}

export const SurveysTable = () => {
  const currentUser = useSelector(selectUser)
  const { data: staticStats, isLoading: statsLoading } = useGetSurveysStats()
  const table = useTable<EngagementSurveyInterface, EngagementSurveyStatsInterface>(
    engagementSurveysTableRequests,
    [
      {
        columnName: 'round_status',
        filters: [
          { id: Statuses.active, name: Statuses.active },
          { id: Statuses.inactive, name: Statuses.inactive },
          { id: Statuses.running, name: Statuses.running },
          { id: Statuses.planned, name: Statuses.planned },
        ],
      },
      {
        nonResettable: true,
        nonInheritable: true,
        columnName: 'status',
        filters: [
          { id: Statuses.active, name: Statuses.active },
          { id: Statuses.inactive, name: Statuses.inactive },
          { id: Statuses.scheduled, name: Statuses.scheduled },
          { id: Statuses.completed, name: Statuses.completed },
          { id: Statuses.archived, name: Statuses.archived },
          { id: Statuses.terminated, name: Statuses.terminated },
        ],
      },
    ],
    [{ sortBy: 'creation_date_time', direction: SORT_DIRECTION.ASC }],
  )

  const isFilteredByMySurveys = !!table.filterBy.find(
    filterBy =>
      filterBy.columnName === 'owner__id' &&
      filterBy.filters.length === 1 &&
      filterBy.filters[0].id === currentUser.id,
  )

  const toggleFilterByMy = () => {
    table.onFilterChange(
      isFilteredByMySurveys
        ? { columnName: 'owner__id', filters: [] }
        : {
            columnName: 'owner__id',
            filters: [{ name: currentUser.display_name, id: currentUser.id }],
          },
    )
  }

  const setStatusFilters = (statuses: Statuses[]) => {
    table.onFilterChange({
      columnName: 'round_status',
      filters: statuses.map(status => ({ id: status, name: status })),
    })
  }

  const selectedFilter = useMemo(() => {
    const roundStatusFilters =
      table.filterBy.find(({ columnName }) => columnName === 'round_status')?.filters ||
      []
    if (roundStatusFilters.length === 1) {
      return roundStatusFilters[0].id
    }
    if (
      roundStatusFilters.length === 4 &&
      roundStatusFilters.some(({ id }) => id === Statuses.active) &&
      roundStatusFilters.some(({ id }) => id === Statuses.inactive) &&
      roundStatusFilters.some(({ id }) => id === Statuses.running) &&
      roundStatusFilters.some(({ id }) => id === Statuses.planned)
    ) {
      return 'total'
    }
    return undefined
  }, [table.filterBy])

  return (
    <TableWidget>
      <TableWidget.Info>
        <Highlights variant="tabs">
          <DataPoint
            aria-selected={selectedFilter === 'total'}
            onClick={() =>
              setStatusFilters([
                Statuses.active,
                Statuses.inactive,
                Statuses.running,
                Statuses.planned,
              ])
            }
          >
            {statsLoading ? (
              <DataPointSkeleton.Value />
            ) : (
              <DataPoint.Value>
                {(staticStats?.running || 0) +
                  (staticStats?.planned || 0) +
                  (staticStats?.inactive || 0)}
              </DataPoint.Value>
            )}
            <DataPoint.Label>Total</DataPoint.Label>
          </DataPoint>
          <DataPoint
            onClick={() => setStatusFilters([Statuses.running])}
            aria-selected={selectedFilter === Statuses.running}
          >
            {statsLoading ? (
              <DataPointSkeleton.Value />
            ) : (
              <DataPoint.Value>{staticStats?.running || 0}</DataPoint.Value>
            )}
            <DataPoint.Label>Running</DataPoint.Label>
          </DataPoint>
          <DataPoint
            aria-selected={selectedFilter === Statuses.planned}
            onClick={() => setStatusFilters([Statuses.planned])}
          >
            {statsLoading ? (
              <DataPointSkeleton.Value />
            ) : (
              <DataPoint.Value>{staticStats?.planned || 0}</DataPoint.Value>
            )}
            <DataPoint.Label>Planned</DataPoint.Label>
          </DataPoint>
          <DataPoint
            aria-selected={selectedFilter === Statuses.inactive}
            onClick={() => setStatusFilters([Statuses.inactive])}
          >
            {statsLoading ? (
              <DataPointSkeleton.Value />
            ) : (
              <DataPoint.Value>{staticStats?.inactive || 0}</DataPoint.Value>
            )}
            <DataPoint.Label>Inactive</DataPoint.Label>
          </DataPoint>
        </Highlights>
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          <MoreBar.Action
            useIcon="Plus"
            use={InternalLink}
            to={ROUTES.FORMS.ENGAGEMENT.SELECT_NEW}
          >
            Create new survey
          </MoreBar.Action>
          <MoreBar.Action
            useIcon="Gear"
            use={InternalLink}
            to={ROUTES.SETTINGS.ENGAGEMENT.EDIT}
          >
            Settings
          </MoreBar.Action>
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton active={isFilteredByMySurveys} onClick={toggleFilterByMy}>
          My surveys
        </FilterButton>
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<EngagementSurveyInterface, EngagementSurveyStatsInterface>
          name={TableNames.EngagementSurveys}
          useWindowScroll
          rowHeight="large"
          hideCountAndButtonSection
          enableSettings={false}
          row={ROW}
          {...table}
          noDataMessage="All engagement surveys will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
