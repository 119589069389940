import React from 'react'
import {
  Tile,
  Avatar,
  Tag,
  IconButton,
  Text,
  LineClamp,
  Ellipsis,
  Token,
  HStack,
  VStack,
} from '@revolut/ui-kit'
import { Pin, Cross } from '@revolut/icons'
import { formatTimeAgo } from '@src/utils/format'
import { compareAsc, formatDistanceToNow } from 'date-fns'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'
import {
  AnnouncementBackgroundIcon,
  getAnnouncementBackgroundIconUrl,
} from '@src/pages/Forms/NotificationTemplate/common'

interface AnnouncementTileProps {
  onClick: () => void
  dismiss: () => void
  color?: string
  showIcon: boolean
  pinned: boolean
  icon?: string | null
  backgroundIcon?: AnnouncementBackgroundIcon | null
  senderName?: string
  title: string
  description?: string
  date?: string
  deadline?: string | null
}

export const AnnouncementTile = ({
  onClick,
  color,
  icon,
  senderName,
  showIcon,
  backgroundIcon,
  pinned,
  dismiss,
  title,
  description,
  date,
  deadline,
}: AnnouncementTileProps) => {
  const isCoreNavigation = useCoreNavigation()

  if (isCoreNavigation) {
    return (
      <Tile
        variant="small"
        onClick={onClick}
        image={
          backgroundIcon ? getAnnouncementBackgroundIconUrl(backgroundIcon) : undefined
        }
        bg={color || Token.color.blue}
        preserveAspectRatio={false}
        data-testid="announcement-tile"
      >
        {showIcon && icon ? (
          <Tile.Avatar>
            <HStack align="center" space="s-8" width="300px">
              <Avatar variant="brand" image={icon} bg={Token.color.white_30} size={24} />
              {senderName ? <Text>{senderName}</Text> : null}
            </HStack>
          </Tile.Avatar>
        ) : null}
        {pinned ? (
          <Tile.Action>
            <Tag useIcon={Pin} variant="outlined" color={Token.color.white}>
              pinned
            </Tag>
          </Tile.Action>
        ) : (
          <Tile.Action>
            <IconButton
              aria-label="Remove"
              useIcon={Cross}
              size={16}
              color={Token.color.white_60}
              onClick={dismiss}
            />
          </Tile.Action>
        )}
        <Tile.Title>{title}</Tile.Title>
        <Tile.Description>
          <VStack space="s-4" maxWidth="68%">
            {description ? <LineClamp max={2}>{description}</LineClamp> : null}
            <Ellipsis>
              <Text variant="caption" color={Token.color.greyTone20}>
                {deadline
                  ? `${formatDistanceToNow(new Date(deadline))} ${
                      compareAsc(Date.now(), new Date(deadline)) === 1
                        ? `overdue`
                        : `left`
                    }`
                  : date
                  ? formatTimeAgo(date)
                  : ''}
              </Text>
            </Ellipsis>
          </VStack>
        </Tile.Description>
      </Tile>
    )
  }

  return (
    <Tile
      variant="extra-small"
      onClick={onClick}
      bg={color || Token.color.black}
      preserveAspectRatio
    >
      {showIcon && icon ? (
        <Tile.Avatar>
          <Avatar variant="brand" image={icon} bg={Token.color.white_30} />
        </Tile.Avatar>
      ) : null}
      {pinned ? (
        <Tile.Action>
          <Tag useIcon={Pin} variant="outlined" color={Token.color.white}>
            pinned
          </Tag>
        </Tile.Action>
      ) : (
        <Tile.Action>
          <IconButton
            aria-label="Remove"
            useIcon={Cross}
            size={16}
            color={Token.color.white_60}
            onClick={dismiss}
          />
        </Tile.Action>
      )}
      <Tile.Description color={Token.color.grey10}>
        <Text
          fontSize="small"
          use="div"
          color={Token.color.white}
          lineHeight={1.1}
          fontWeight={500}
        >
          <LineClamp max={2}>{title}</LineClamp>
        </Text>
        <Ellipsis>
          {deadline
            ? `${formatDistanceToNow(new Date(deadline))} ${
                compareAsc(Date.now(), new Date(deadline)) === 1 ? `overdue` : `left`
              }`
            : date
            ? formatTimeAgo(date)
            : ''}
        </Ellipsis>
      </Tile.Description>
    </Tile>
  )
}
