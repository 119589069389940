import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import {
  ROW as personalKPIRow,
  usePersonalKPITable,
} from '@src/pages/EmployeeProfile/Preview/KPI/PersonalKPI'
import React, { useEffect } from 'react'
import { chain, Flex, Token, VStack } from '@revolut/ui-kit'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { summaryTableProps } from '@src/features/Summary/common'
import styled from 'styled-components'
import { withoutFilterAndSort } from '@src/utils/table'
import {
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

const StyledText = styled(Flex)`
  align-items: center;
  color: ${Token.color.greyTone50};
  font-weight: 500;
`

const kpiRow = {
  ...personalKPIRow,
  cells: withoutFilterAndSort([
    {
      ...kpiGenericNameColumn,
      title: 'Name',
      width: 300,
    },
    {
      ...kpiWeightColumn,
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
  ]),
}

export const NoReviewsDeliverables = ({
  data,
  stats,
  selectedPeriod,
  category,
}: {
  data: Pick<EmployeeInterface, 'id'>
  stats?: Pick<EmployeeStats, 'kpi_performance_percent'>
  selectedPeriod: Pick<PerformanceSelector, 'id'>
  category?: ReviewCycleCategory
}) => {
  const kpiTable = usePersonalKPITable(data, { refetchOnApiChange: true }, [
    {
      filters: [{ name: `${selectedPeriod.id}`, id: selectedPeriod.id }],
      columnName: 'cycle__id',
      nonResettable: true,
    },
  ])

  useEffect(() => {
    const cycleFilterId = kpiTable.filterBy.find(
      filter => filter.columnName === 'cycle__id',
    )?.filters[0]?.id
    if (selectedPeriod.id !== cycleFilterId) {
      kpiTable.onFilterChange([
        {
          filters: [{ name: `${selectedPeriod.id}`, id: selectedPeriod.id }],
          columnName: 'cycle__id',
          nonResettable: true,
        },
      ])
    }
  }, [selectedPeriod])

  return (
    <VStack space="s-16">
      <StyledText>
        {chain(
          'Deliverables',
          <ColoredPercent percent={(stats?.kpi_performance_percent || 0) * 100} />,
        )}
      </StyledText>
      {category === ReviewCycleCategory.Performance && (
        <AdjustableTable<KpiInterface>
          name={TableNames.EmployeeKPIs}
          dataType="KPI"
          row={kpiRow}
          {...kpiTable}
          noDataMessage="All your goals for this cycle will appear here"
          onRowClick={onKPITableRowClick}
          {...summaryTableProps}
          hideCountAndButtonSection
        />
      )}
    </VStack>
  )
}
