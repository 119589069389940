import React, { useEffect, useRef, useState } from 'react'
import { KpiInterface, KpiReviewCycle, UpdateTypes } from '@src/interfaces/kpis'
import { Box, InputGroup } from '@revolut/ui-kit'
import { kpisRequestsNew } from '@src/api/kpis'
import Form from '@src/features/Form/Form'
import { LoadingState } from './LoadingState'
import { useMetricFormCache } from '../../useGoalFormCache'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { GoalTargetCycleSelector } from '../../common/GoalTargetCycleSelector'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCycle } from '../../common/CycleSelector'
import { useFormObserver } from '../Widgets/FormObserverProvider'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { captureException } from '@sentry/react'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { MetricTypeSelector } from './MetricTypeSelector'
import { TargetFieldsByType } from './TargetFieldsByType'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

interface Props {
  initialValues: Partial<KpiInterface>
  initialCycle?: ReviewCycle
  owner?: EmployeeOptionInterface
}

const GoalMetricFormBody = ({
  initialCycle,
  owner,
}: Pick<Props, 'initialCycle' | 'owner'>) => {
  const { cacheUpdated } = useMetricFormCache()
  const validator = useFormValidator()
  const { registerForm, unregisterForm } = useFormObserver()
  const form = useLapeContext<KpiInterface & { tempId?: number }>()
  const { values, apiErrors } = form
  const formRef = useRef({ form, validator })
  const [formId] = useState(values.tempId || values.id)

  const cycleError =
    'detail' in apiErrors &&
    typeof apiErrors.detail === 'string' &&
    (apiErrors.detail.includes('review cycle') ||
      apiErrors.detail.includes('Only one metric per cycle'))
      ? apiErrors?.detail
      : undefined

  const handleCycleSelection = (cycle: ReviewCycle) => {
    if (!cycle) {
      return
    }
    if (cycle.category === ReviewCycleCategory.Probation) {
      if (!values.targets?.length) {
        // edge case when on draft goal BE may return null instead of empty array
        // @ts-expect-error partial data for form
        values.targets = [{ employee_cycle: cycle }]
        if (values.update_type === UpdateTypes.roadmap) {
          // @ts-expect-error partial data for form
          values.target_epics = [{ employee_cycle: cycle }]
        }
        return
      }
      values.targets?.forEach(target => {
        target.employee_cycle = cycle
        target.review_cycle = undefined
      })
      values.target_epics?.forEach(target => {
        target.employee_cycle = cycle
        target.review_cycle = undefined
      })
    } else {
      if (!values.targets?.length) {
        // edge case when on draft goal BE may return null instead of empty array
        // @ts-expect-error partial data for form
        values.targets = [{ employee_cycle: cycle }]
        if (values.update_type === UpdateTypes.roadmap) {
          // @ts-expect-error partial data for form
          values.target_epics = [{ employee_cycle: cycle }]
        }
        return
      }
      values.targets?.forEach(target => {
        target.employee_cycle = undefined
        target.review_cycle = cycle as KpiReviewCycle
      })
      values.target_epics?.forEach(target => {
        target.employee_cycle = undefined
        target.review_cycle = cycle as KpiReviewCycle
      })
    }
  }

  useEffect(() => {
    // when the owner changed we want to update metrics owner as well
    // especially relevant when selecing performance cycles for employee
    // as they will be different for employees on probation
    if (owner && owner.id !== values.owner.id) {
      values.owner.id = owner.id
      values.owner.name = owner.name
    }
  }, [owner])

  useEffect(() => {
    // update jira flow with cycles on the flow changed
    if (values.update_type && values.update_type === UpdateTypes.roadmap) {
      if (values.target_epics.at(0)) {
        values.target_epics.at(0)!.employee_cycle = values.targets.at(0)?.employee_cycle
        values.target_epics.at(0)!.review_cycle = values.targets.at(0)?.review_cycle
      }
    }
  }, [values.update_type])

  useEffect(() => {
    // registered form nees to be updated with form and it's validator
    formRef.current = { form, validator }
  }, [form, validator])

  useEffect(() => {
    // need to register this form so that in useGoalFormSubmit we can submit metric separate to goals
    if (typeof formId === 'number') {
      registerForm(() => formRef.current, formId)
    } else {
      console.error('Failed to register form due to a missed id', formId)
      captureException('Failed to register form due to a missed id')
    }

    return () => unregisterForm(values.id)
  }, [formId])

  return cacheUpdated ? (
    <InputGroup>
      <GoalTargetCycleSelector
        disabled={initialCycle?.category === ReviewCycleCategory.Probation}
        onChange={selection => {
          if ('detail' in apiErrors) {
            delete apiErrors.detail
          }
          handleCycleSelection(selection)
        }}
        isEmployee={values.is_employee}
        ownerId={values.owner?.id}
        initialCycle={initialCycle}
        hasError={!!cycleError}
        message={cycleError}
      />
      <LapeNewInput name="name" label="Metric name" required />
      {values.id ? null : <MetricTypeSelector />}
      <TargetFieldsByType type={values.update_type} />
    </InputGroup>
  ) : null
}

export const GoalMetricForm = ({ initialValues, ...rest }: Props) => {
  return (
    <Box>
      <Form<KpiInterface>
        api={kpisRequestsNew}
        forceParams={{ id: initialValues.id ? String(initialValues.id) : undefined }}
        initialValues={initialValues}
        disableLocalStorageCaching
        ignoreLocationState
        loadingState={LoadingState}
      >
        <GoalMetricFormBody {...rest} />
      </Form>
    </Box>
  )
}
