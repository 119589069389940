import { IconName } from '@revolut/ui-kit'
import { CYCLE_DURATION } from '@src/interfaces/settings'

export const cycleOptionMap: Record<
  CYCLE_DURATION,
  { iconName: IconName; description: string }
> = {
  quarterly: {
    description:
      'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
    iconName: 'RadiobuttonOff',
  },
  biannually: {
    description:
      'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
    iconName: 'Calendar',
  },
  annually: {
    description:
      "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
    iconName: 'Calendar',
  },
}
