import React from 'react'
import { AppIcon, AppIconGrid, TopNav } from '@revolut/ui-kit'
import { AppSwitcher } from '@revolut-internal/backoffice-ui-provider'
import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const TopNavAppSwitcher = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return isCommercial ? (
    <TopNav.AppSwitcher>
      <AppIconGrid>
        <AppIcon
          use="a"
          href="https://app.revolut.com"
          target="_blank"
          image="https://assets.revolut.com/assets/rev-apps/retail.png"
        >
          <AppIcon.Title>Banking</AppIcon.Title>
        </AppIcon>
        <AppIcon
          use="a"
          href="https://business.revolut.com"
          target="_blank"
          image="https://assets.revolut.com/assets/rev-apps/business.png"
        >
          <AppIcon.Title>Business</AppIcon.Title>
        </AppIcon>
        <AppIcon
          use="a"
          href="https://trade.revolut.com"
          target="_blank"
          image="https://assets.revolut.com/assets/rev-apps/invest.png"
        >
          <AppIcon.Title>Invest</AppIcon.Title>
        </AppIcon>
        <AppIcon
          use="a"
          href="https://exchange.revolut.com"
          target="_blank"
          image="https://assets.revolut.com/assets/rev-apps/crypto-exchange.png"
        >
          <AppIcon.Title>Revolut X</AppIcon.Title>
        </AppIcon>
        <AppIcon
          use="a"
          href="https://revolutpeople.com"
          target="_blank"
          image="https://assets.revolut.com/assets/rev-apps/people.png"
        >
          <AppIcon.Title>People</AppIcon.Title>
        </AppIcon>
      </AppIconGrid>
    </TopNav.AppSwitcher>
  ) : (
    <AppSwitcher />
  )
}
