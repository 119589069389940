import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import {
  probationManagersRulesColumn,
  probationTemplateCheckpointsColumn,
  probationTemplateDurationColumn,
  probationTemplateNameColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
} from '@src/constants/columns/probation'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@components/InternalLink/InternalLink'
import SettingsButtons from '@src/features/SettingsButtons'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import { getProbationTemplates } from '@src/api/probationTemplate'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'

const Row: RowInterface<ProbationTemplateInterface> = {
  cells: [
    {
      ...probationTemplateNameColumn,
      width: 200,
    },
    {
      ...probationTemplateDurationColumn,
      width: 50,
    },
    {
      ...probationTemplateCheckpointsColumn,
      width: 50,
    },
    {
      ...probationTemplatePolicyColumn,
      width: 50,
    },
    {
      ...probationManagersRulesColumn,
      width: 150,
    },
    {
      ...probationTemplateStatusColumn,
      width: 80,
    },
  ],
}

export const ProbationTemplatesTable = () => {
  const table = useTable<ProbationTemplateInterface>({ getItems: getProbationTemplates })

  return (
    <PageWrapper>
      <PageHeader
        title="Probation templates"
        backUrl={ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_TEMPLATES}
      />
      <Cell display="flex" width="100%">
        <Flex flexDirection="column" width="100%">
          <SettingsButtons mb="s-24">
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.PROBATION.TEMPLATE)}
              useIcon={Plus}
            >
              Add new template
            </MoreBar.Action>
          </SettingsButtons>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<ProbationTemplateInterface>
              name={TableNames.ProbationTemplates}
              useWindowScroll
              row={Row}
              {...table}
              noDataMessage="Probation templates will appear here."
              onRowClick={data =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.PROBATION.TEMPLATE_VIEW, { id: data.id }),
                )
              }
            />
          </Flex>
        </Flex>
      </Cell>
    </PageWrapper>
  )
}
