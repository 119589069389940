import { CycleFilterProps } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React from 'react'
import { PerformanceLayoutCycleFilter } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { EntityTypes } from '@src/constants/api'
import { useGetEmployeeCyclesSelector } from '../common/useGetEmployeeCyclesSelector'
import { OptionInterface } from '@src/interfaces/selectors'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'

export const CycleFilter = ({
  employeeId,
  columnName = 'review_cycle__offset',
  setShouldHideAddButton,
  setCycleCategory,
  ...rest
}: CycleFilterProps & {
  employeeId?: number
  setShouldHideAddButton?: (hidden: boolean) => void
  setCycleCategory?: (val: ReviewCycleCategory) => void
}) => {
  const { cycleSelector } = useGetEmployeeCyclesSelector(employeeId, true)
  const onChange = (val: OptionInterface) => {
    if (setShouldHideAddButton) {
      if (
        'category' in val &&
        val.category === ReviewCycleCategory.Probation &&
        val.status === Statuses.closed
      ) {
        setShouldHideAddButton(true)
      } else {
        setShouldHideAddButton(false)
      }
    }
    if (setCycleCategory && 'category' in val) {
      setCycleCategory(val.category as ReviewCycleCategory)
    }
  }
  return (
    <PerformanceLayoutCycleFilter
      label="Cycle"
      columnName={columnName}
      onChangeCallback={onChange}
      {...rest}
      selector={employeeId ? cycleSelector : rest.selector}
    />
  )
}

export const GoalCycleFilter = ({
  allowEmployeeCycles = true,
  ...cycleFilterProps
}: CycleFilterProps & {
  allowEmployeeCycles?: boolean
  setShouldHideAddButton?: (hidden: boolean) => void
  setCycleCategory?: (val: ReviewCycleCategory) => void
}) => {
  const { entity } = useOrgEntity()

  if (!entity) {
    // nice-to-have: add a proper loading state. not needed for now as whole tab mount waits for entity
    return null
  }

  const isEmployee = [EntityTypes.employee, EntityTypes.employees].includes(entity.type)

  return (
    <CycleFilter
      {...cycleFilterProps}
      employeeId={isEmployee && allowEmployeeCycles ? entity.data.id : undefined}
    />
  )
}
