import { StatusPopup, useStatusPopup, StatusPopupVariant } from '@revolut/ui-kit'
import React from 'react'

type UseShowStatusPopupData = {
  title: React.ReactNode
  description?: React.ReactNode
  status?: StatusPopupVariant
  actions?: React.ReactNode
}

export const useShowStatusPopup = () => {
  const statusPopup = useStatusPopup()

  return ({
    status = 'success',
    title,
    description,
    actions,
  }: UseShowStatusPopupData) => {
    statusPopup.show(
      <StatusPopup variant={status}>
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
        {actions && <StatusPopup.Actions>{actions}</StatusPopup.Actions>}
      </StatusPopup>,
    )
  }
}
