import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, SORT_DIRECTION, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { notificationsRequests } from '@src/api/notifications'
import {
  notificationCategory,
  notificationDescription,
  notificationsActionColumn,
  notificationsEmployeeColumn,
  notificationsDeadline,
} from '@src/constants/columns/notifications'
import { Flex, Cell } from '@revolut/ui-kit'
import ChatTagsManager from '@components/Chat/ChatTagsManager'
import { useIsNewTable } from '@components/TableV2/hooks'

const AllNotifications = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
  onCountChange,
}: TabComponentProps) => {
  const isNewTable = useIsNewTable()

  const initialSortBy = [
    {
      sortBy: 'deadline',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId, isNewTable),
        width: 600,
      },
      isNewTable
        ? {
            ...notificationsDeadline,
            width: 200,
          }
        : null,
      {
        ...notificationCategory,
        width: 240,
      },
      {
        ...notificationsEmployeeColumn,
        width: 280,
      },
      isNewTable
        ? null
        : {
            ...notificationsDeadline,
            width: 200,
          },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ].filter(Boolean),
  }

  if (type === 'dashboard') {
    return (
      <ChatTagsManager noTooltip>
        <GenericNotificationTable
          rows={RowNotifications}
          request={notificationsRequests()}
          refreshStats={refreshStats}
          type={type}
          filterBy={filterBy}
          sortBy={initialSortBy}
          tableRef={tableRef}
          onCountChange={onCountChange}
        />
      </ChatTagsManager>
    )
  }

  return (
    <Cell p="0">
      <Flex flexDirection="column" width="100%">
        <ChatTagsManager noTooltip>
          <GenericNotificationTable
            rows={RowNotifications}
            request={notificationsRequests(employeeId)}
            refreshStats={refreshStats}
            type={type}
            filterBy={filterBy}
            sortBy={initialSortBy}
            tableRef={tableRef}
          />
        </ChatTagsManager>
      </Flex>
    </Cell>
  )
}

export default AllNotifications
