import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { format, isAfter, isBefore, isSameDay } from 'date-fns'
import get from 'lodash/get'
import { Box, Color, Flex, Item, Text, Token, VStack, Widget } from '@revolut/ui-kit'

import { cancelPIP, useGetGoals, useGetPerformanceSelector } from '@src/api/performance'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceSidebarManager,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import {
  getPerformanceReviewSummary,
  performanceReviewRequests,
  rejectReview,
} from '@src/api/performanceReview'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ProbationGoalInterface } from '@src/interfaces/probationReview'
import {
  getProbationGoals,
  getProbationKPIs,
  probationCommentsApi,
  useGetProbationCheckpoints,
} from '@src/api/probationReview'
import {
  closeProbationCycle,
  deleteProbationCycle,
  regenerateProbationScorecards,
} from '@src/api/probationEmployees'
import { Scorecard } from '@src/features/Scorecard/Scorecard'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { EmployeeGoal, EmployeeInterface } from '@src/interfaces/employees'
import PerformanceReviewPopup from '@src/features/Popups/PerformanceReviewPopup'
import RejectReviewPopup from '@src/features/Popups/RejectReviewPopup'
import { Statuses } from '@src/interfaces'
import PerformanceGrade from '@components/PerformanceGrade/PerformanceGrade'
import { useQueryClient } from 'react-query'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { KpiInterface } from '@src/interfaces/kpis'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { getPipGoals, pipCommentsApi, useGetPipCheckpoints } from '@src/api/pip'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import SummarySidebar from '../Summary/SummarySidebar'
import Goals from '../Goals/Goals'
import PerformanceCycleSelect from '../Common/PerformanceCycleSelect'
import PerformanceActions from '../Common/PerformanceActions'
import {
  checkCanRequestFeedback,
  checkCanViewPIPv2Settings,
  checkIsPipPeriod,
  checkIsProbationPeriod,
  checkRejectAvailability,
  getReviewer,
  isNewPerformancePeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '../Common/utils'
import ProbationTimeline from '../ProbationTimeline/ProbationTimeline'
import PipReviewForm from './PIPReviewForm'
import Reviews from './Reviews'
import RequestFeedback from './RequestFeedback'
import { AddReviewErrorPopup } from './AddReviewErrorPopup'
import ProbationGoalsJiraDisabled from './ProbationGoalsJiraDisabled'
import ProbationGoals from './ProbationGoals'
import KeyDates from './KeyDates'
import { useGetPeriodTypes } from '@src/utils/performance'
import { getAvatarUrl } from '@src/utils/employees'
import { defaultTheme } from '@src/styles/theme'

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 676px auto;
  padding-bottom: 32px;
`

const PositionedScorecard: typeof Scorecard = styled(Scorecard)`
  box-shadow: -9px 5px 29px 0 ${Token.color.greyTone2};
`

const Content = styled.div``
const Side = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${defaultTheme.zIndex.tooltip};
  height: 100%;
`

const TimelineLink = styled(Link)`
  color: ${Token.color.blue};
  text-decoration: none;
`

const Grade = styled(PerformanceGrade)`
  padding: 2px 8px;
  border-radius: 4px;
  flex-shrink: 0;
`

type RequestsResponseType =
  | (PerfReviewRequestFeedbackInterface | RequestFeedbackInterface)[]
  | undefined

export interface PerformanceFormProps {
  data: EmployeeInterface
}

const PerformanceReview = ({ data }: PerformanceFormProps) => {
  const { query } = useQuery(true)
  const user = useSelector(selectUser)

  const queryClient = useQueryClient()
  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)
  const { data: performanceSettings } = useGetPerformanceSettings()

  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [scorecardLoading, setScorecardLoading] = useState(true)
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [submitReviewDisabledReason, setSubmitReviewDisabledReason] = useState<
    string | null
  >('You are not allowed to submit review at this time')
  const [userReviewStatus, setUserReviewStatus] = useState<Statuses | null>(null)
  const [addingPIPReview, setAddingPIPReview] = useState(
    !!query.request_id || !!query.editing_id,
  )
  const [PIPScorecardFilter, setPIPScorecardFilter] = useState<OptionInterface>()
  const [showWelcomePopup, setShowWelcomePopup] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState<PerformanceSidebarManager>(
    PerformanceSidebarManager.SummaryOpened,
  )
  const [userRequest, setUserRequest] = useState<
    PerfReviewRequestFeedbackInterface | RequestFeedbackInterface
  >()
  const [tickets, setTickets] = useState<(ProbationGoalInterface | KpiInterface)[]>()
  const [finalGrade, setFinalGrade] = useState<FinalGrade | undefined>()
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)
  const [errorPopupTitle, setErrorPopupTitle] = useState<string | undefined>()

  const canRequestFeedback =
    typeof performanceSettings?.enable_peer_reviews !== 'undefined'
      ? performanceSettings?.enable_peer_reviews
      : checkCanRequestFeedback(data)
  const canRejectReview = checkRejectAvailability(userRequest)
  const canManageGoals = get(data, 'field_options.permissions', []).includes(
    PermissionTypes.SetGooalsEmployees,
  )
  const canRegenerateScorecards = data.field_options.permissions?.includes(
    PermissionTypes.ProbationCommitteeHRPermissions,
  )
  const permissions = useSelector(selectPermissions)
  const canDeleteProbationCycle = permissions.includes(
    PermissionTypes.DeleteEmployeePerformanceCycle,
  )

  const cycleId = selectedPeriod?.id

  const probationDeliverablesType = selectedPeriod?.probation_reviews_deliverables_type

  const isLineManager = user.id === data.line_manager?.id
  const isFunctionalManager = user.id === data.quality_control?.id

  const isProbationPeriod = checkIsProbationPeriod(data)
  const isPipPeriod = checkIsPipPeriod(selectedPeriod)

  const canViewPIPv2Settings = checkCanViewPIPv2Settings(selectedPeriod)

  const isNewPerformance = useMemo(
    () => !!selectedPeriod && isNewPerformancePeriod(selectedPeriod),
    [selectedPeriod],
  )

  const canViewProbationComments =
    permissions.includes(PermissionTypes.ProbationCommitteeHRPermissions) ||
    canRegenerateScorecards ||
    isLineManager ||
    isFunctionalManager

  const { isNewProbation, isOldProbation, isPIP, isPIPv2, isNewFlow } =
    useGetPeriodTypes(selectedPeriod)

  const canAddPerformanceReview =
    isNewPerformance && (isLineManager || user.id === data.quality_control?.id)

  const isProbation = selectedPeriod?.category === ReviewCycleCategory.Probation

  const canAddReview =
    !isNewProbation &&
    !isPIPv2 &&
    (get(data, 'field_options.permissions', []).includes(PermissionTypes.AddReview) ||
      userRequest ||
      canAddPerformanceReview)

  const canChangeCycleSettings =
    canRegenerateScorecards && (isProbationPeriod || isPipPeriod)

  const filters = [
    {
      filters: [{ name: `${data.id}`, id: `${data.id}` }],
      columnName: 'employee__id',
    },
    selectedPeriod?.category !== ReviewCycleCategory.Probation
      ? {
          filters: [{ name: `${cycleId}`, id: `${cycleId}` }],
          columnName: 'review_cycle__id',
        }
      : {
          filters: [{ name: selectedPeriod?.category, id: selectedPeriod?.category }],
          columnName: 'category',
        },
  ]

  const { data: jiraDisabledGoals = [] } = useGetGoals(
    selectedPeriod?.category && data.id && selectedPeriod?.end_date_time ? data.id : null,
    filters,
  )

  const initialCategory = useMemo(() => {
    if (isProbationPeriod) {
      return ReviewCategory.Probation
    }
    if (isPIPv2) {
      return ReviewCategory.PIP_V2
    }
    return ReviewCategory.Performance
  }, [isProbationPeriod, isPIPv2])

  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const pipJiraDisabled = featureFlags.includes(
    FeatureFlags.PipGoalsJiraIntegrationDisabled,
  )
  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira &&
    (!isPIPv2 || !pipJiraDisabled)

  const { data: checkpoints, refetch: refetchCheckpoints } = useGetProbationCheckpoints(
    isNewProbation ? data.id : null,
    cycleId !== undefined ? String(cycleId) : null,
  )

  const { data: pipCheckpoints } = useGetPipCheckpoints(
    isPIPv2 ? data.id : null,
    cycleId !== undefined ? String(cycleId) : null,
  )

  const requestsContext = useFetcherPerformanceRequests({
    category: initialCategory,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })

  const {
    data: requestsData,
    isLoading: fetchingRequests,
    refetch: refetchRequests,
  } = requestsContext

  const requests: RequestsResponseType = isNewFlow
    ? (requestsData as NewFlowRequestsResponse | undefined)?.results
    : (requestsData as RequestFeedbackInterface[] | undefined)

  useEffect(() => {
    const foundRequest = requests?.find(
      request => getReviewer(request, isNewPerformance)?.id === user.id,
    )

    setUserReviewStatus(foundRequest?.status || null)
    setUserRequest(foundRequest)
  }, [requests])

  const fetchPerformanceSelector = () => {
    if (performanceSelectorData) {
      let selectors = performanceSelectorData.filter(selector => {
        if (selector.category === ReviewCycleCategory.PIP) {
          return true
        }
        if (selector.category === ReviewCycleCategory.Probation) {
          const startDateAdjusted = new Date(selector.start_date_time)
          startDateAdjusted.setDate(startDateAdjusted.getDate() - 7)
          return isBefore(startDateAdjusted, new Date())
        }
        return isBefore(new Date(selector.start_date_time), new Date())
      })

      selectors = selectors.map(selector => {
        if (
          selector.category === ReviewCycleCategory.Probation ||
          selector.category === ReviewCycleCategory.PIP
        ) {
          const startDate = format(new Date(selector.start_date_time), 'd MMM')
          const endDate = format(new Date(selector.end_date_time), 'd MMM')

          return {
            ...selector,
            name: `${
              selector.category === ReviewCycleCategory.PIP ? 'PIP' : selector.name
            } · ${startDate} - ${endDate}`,
          }
        }

        return selector
      })

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      if (isProbationPeriod || query.cycle_id === 'null') {
        setSelectedPeriod(
          selectors.find(selector => selector.category === ReviewCycleCategory.Probation),
        )
      } else if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          setSelectedPeriod(
            selectors.find(selector => {
              return `${selector.id}` === query.cycle_id
            }),
          )
        } else {
          setSelectedPeriod(
            selectors.find(selector => selector.performance_reviews_selected_cycle),
          )
        }
      }
      setScorecardLoading(false)
      return selectors as OptionInterface[]
    }

    return []
  }

  const checkAddReviewAvailability = () => {
    if (!selectedPeriod) {
      setSubmitReviewDisabledReason('Please select review cycle')
      return
    }

    if (isNewPerformance) {
      if (selectedPeriod.requester_can_submit_review_v3) {
        setSubmitReviewDisabledReason(null)
        return
      }

      const now = new Date()
      const startDate = new Date(selectedPeriod.review_period_start_day)

      if (isBefore(now, startDate) && !isSameDay(now, startDate)) {
        setSubmitReviewDisabledReason('This performance cycle has not started yet')
        return
      }

      setSubmitReviewDisabledReason('You are not allowed to submit review at this time')
      return
    }

    if (
      selectedPeriod.start_date_time &&
      (selectedPeriod.end_date_time || selectedPeriod.scorecards_lock_date_time)
    ) {
      const today = new Date()
      const from = new Date(selectedPeriod.start_date_time)
      const to = new Date(selectedPeriod.end_date_time)
      const lockDate =
        !!selectedPeriod.scorecards_lock_date_time &&
        new Date(selectedPeriod.scorecards_lock_date_time)
      if (
        lockDate ? isAfter(today, lockDate) : isAfter(today, to) && !isSameDay(today, to)
      ) {
        setSubmitReviewDisabledReason('This performance cycle has already ended')
      } else if (isBefore(today, from) && !isSameDay(today, from)) {
        setSubmitReviewDisabledReason('This performance cycle has not started yet')
      } else {
        setSubmitReviewDisabledReason(null)
      }
    }
  }

  const handleFetchRequests = () => {
    queryClient.invalidateQueries({
      // fixme: remove workaround whithin REVC-3416
      predicate: ({ queryHash }) => !queryHash.includes('performanceSettings'),
    })
  }

  useEffect(() => {
    handleFetchRequests()
  }, [initialCategory, selectedPeriod])

  const fetchFinalGrade = useCallback(async () => {
    if (cycleId === undefined) {
      return
    }

    const category =
      selectedPeriod?.category === ReviewCycleCategory.Probation
        ? ReviewCategory.Probation
        : selectedPeriod?.category === ReviewCycleCategory.PIP
        ? ReviewCategory.PIP_V2
        : ReviewCategory.Performance

    const resp = await getPerformanceReviewSummary(String(cycleId), data.id, category, [
      {
        columnName: 'reviewer_relation',
        filters: [
          {
            id: ReviewerRelation.LineManager,
            name: ReviewerRelation.LineManager,
          },
          {
            id: ReviewerRelation.FunctionalManager,
            name: ReviewerRelation.FunctionalManager,
          },
        ],
      },
    ])
    setFinalGrade(resp.data?.grade_after_calibration?.display_grade)
  }, [cycleId])

  const onAddRequest = () => {
    handleFetchRequests()
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [initialCategory, performanceSelectorData])

  useEffect(() => {
    // we should not unblock button if it's probation and user is line manager
    if (!selectedPeriod || selectedPeriod.category !== ReviewCycleCategory.Probation) {
      checkAddReviewAvailability()
    }
    fetchFinalGrade()
  }, [selectedPeriod, initialCategory, cycleId])

  useEffect(() => {
    if (!isNewProbation || cycleId === undefined) {
      return
    }

    const fetchTickets = async () => {
      const result =
        probationDeliverablesType === 'kpi' || probationDeliverablesType === 'goal'
          ? await getProbationKPIs(data.id, String(cycleId), goalsEnabled)
          : await getProbationGoals(data.id, String(cycleId))
      const results = [...result.data.results]

      setTickets(results)
    }

    fetchTickets()
  }, [cycleId, isNewProbation])

  useEffect(() => {
    if (!isPIPv2 || cycleId === undefined) {
      return
    }

    const fetchTickets = async () => {
      const result = await getPipGoals(data.id, String(cycleId))

      setTickets(result.data.results)
    }

    fetchTickets()
  }, [cycleId, isPIPv2])

  useEffect(() => {
    data.field_options.disableSubmit = addingPIPReview
  }, [addingPIPReview])

  useEffect(() => {
    if (query.request_id) {
      setAddingPIPReview(true)
    }
  }, [query.request_id])

  useEffect(() => {
    if (PIPScorecardFilter) {
      setSidebarOpen(PerformanceSidebarManager.SummaryOpened)
    }
  }, [PIPScorecardFilter])

  const onClickViewReview = () => {
    setPIPScorecardFilter(undefined)
    if (sidebarOpen === PerformanceSidebarManager.SummaryOpened) {
      setSidebarOpen(PerformanceSidebarManager.Closed)
    } else {
      setSidebarOpen(PerformanceSidebarManager.SummaryOpened)
    }
  }

  const onClickRequestReview = () => {
    if (sidebarOpen === PerformanceSidebarManager.RequestOpened) {
      setSidebarOpen(PerformanceSidebarManager.Closed)
    } else {
      setSidebarOpen(PerformanceSidebarManager.RequestOpened)
    }
  }

  const navigateToReview = async () => {
    if (!selectedPeriod) {
      return
    }

    try {
      const resp = await performanceReviewRequests.submit(
        {
          reviewed_employee: data,
          cycle: cycleId
            ? {
                id: cycleId,
              }
            : undefined,
        },
        {},
      )

      navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, {
          employeeId: data.id,
          id: resp.data.id,
        }),
      )
    } catch (e) {
      setErrorPopupOpen(true)
      setErrorPopupTitle(
        JSON.stringify(e?.response?.data, undefined, 2).replace(/\[|\]|"|{|}|_|:/g, ' '),
      )
    }
  }

  const handleAddReviewButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!selectedPeriod) {
      return
    }

    if (!isNewPerformance) {
      setAddingPIPReview(true)
      return
    }

    await navigateToReview()
  }

  const handleCancelPipButtonClick = async () => {
    if (!selectedPeriod || !isPIP) {
      return
    }

    await cancelPIP(data.id, selectedPeriod.id)
    setSelectedPeriod(
      performanceSelector?.find(period => period.performance_reviews_selected_cycle),
    )
  }

  const handleRegenerateScorecardsButtonClick = async () => {
    await regenerateProbationScorecards(getReviewCycleIdWithoutPrefix(cycleId))
    refetchCheckpoints()
    refetchRequests()
  }

  const handleCloseProbationButtonClick = async () => {
    await closeProbationCycle(getReviewCycleIdWithoutPrefix(cycleId))
    refetchCheckpoints()
    refetchRequests()
  }

  const handleDeleteProbationButtonClick = async () => {
    await deleteProbationCycle(getReviewCycleIdWithoutPrefix(cycleId))
  }

  const onGoalsUpdated = (goals: EmployeeGoal[]) => {
    if (selectedPeriod && selectedPeriod.category === ReviewCycleCategory.Probation) {
      if (goals.length && goals[0].name) {
        checkAddReviewAvailability()
      } else {
        setSubmitReviewDisabledReason(
          'Personal goals should be set by the line manager prior to a review submission.',
        )
      }
    }
  }

  const renderNotesReviews = () => {
    if (!selectedPeriod) {
      return null
    }

    if (
      selectedPeriod.category !== ReviewCycleCategory.Probation &&
      selectedPeriod.category !== ReviewCycleCategory.PIP
    ) {
      return null
    }

    if (isNewProbation || isPIPv2) {
      return null
    }

    if (
      // we should not show this if the end of probation before 18 Feb
      selectedPeriod.category === ReviewCycleCategory.Probation &&
      isBefore(new Date(data.end_of_probation_date_time), new Date('2020-02-18'))
    ) {
      return null
    }

    const { reviews } = selectedPeriod

    if (!reviews.length) {
      return null
    }

    return <KeyDates category={selectedPeriod.category} reviews={reviews} />
  }

  const handleReject = async () => {
    await rejectReview(data.id, userRequest?.id!.toString()!)
    pushNotification({
      value: `Review request successfully rejected.`,
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })

    handleFetchRequests()
  }

  const handleOpenPipSettings = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PIP.SETTINGS, {
        employeeId: data.id,
        id: cycleId,
      }),
    )
  }

  const handleOpenCycleSettings = () => {
    if (
      selectedPeriod?.category === ReviewCycleCategory.PIP &&
      selectedPeriod?.version === 1
    ) {
      navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.PIP, { id: data.id }))
    } else {
      const url =
        selectedPeriod?.category === ReviewCycleCategory.PIP
          ? ROUTES.FORMS.PIP_OVERVIEW.CYCLE_SETTINGS
          : ROUTES.FORMS.PROBATION_OVERVIEW.CYCLE_SETTINGS
      navigateTo(pathToUrl(url, { employeeId: data.id, cycleId }))
    }
  }

  if (showWelcomePopup) {
    return (
      <PerformanceReviewPopup
        name={data?.display_name}
        firstName={data?.first_name}
        jobTitle={data?.job_title}
        onClose={() => setShowWelcomePopup(false)}
        onSubmit={navigateToReview}
        category={initialCategory}
      />
    )
  }

  if (addingPIPReview) {
    return (
      <PipReviewForm
        data={data}
        scorecardLoading={scorecardLoading}
        onCancelEditing={() => {
          setAddingPIPReview(false)
        }}
        selectedPeriod={selectedPeriod}
        setFilter={setPIPScorecardFilter}
        onSubmit={() => {
          setAddingPIPReview(false)
          handleFetchRequests()
        }}
      />
    )
  }

  const hr = selectedPeriod?.hr_manager
  const additionalSettings = [
    {
      title: 'HR manager',
      value: hr ? (
        <UserWithAvatar id={hr.id} name={hr.full_name} avatar={getAvatarUrl(hr.avatar)} />
      ) : (
        '-'
      ),
    },
    {
      title: 'Probation template',
      value: data.probation_template?.name || '-',
    },
  ]

  return (
    <Wrap>
      <AddReviewErrorPopup
        open={errorPopupOpen}
        onClose={() => {
          setErrorPopupOpen(false)
          setErrorPopupTitle(undefined)
        }}
        title={errorPopupTitle}
      />
      {userRequest?.id && selectedPeriod && isNewFlow && (
        <RejectReviewPopup
          request={userRequest as PerfReviewRequestFeedbackInterface}
          performanceCycle={selectedPeriod}
          employee={data}
          onReject={handleReject}
        />
      )}
      <Content>
        <Widget p="s-16" mt="s-16">
          <Flex justifyContent="space-between" alignItems="center">
            <PerformanceCycleSelect
              selectedPeriod={selectedPeriod}
              performanceSelector={performanceSelector}
              onChange={selector => setSelectedPeriod(selector)}
            />
            {finalGrade && <Grade grade={finalGrade} />}
          </Flex>

          <PerformanceActions
            submitReviewDisabledReason={submitReviewDisabledReason}
            onClickAddReview={handleAddReviewButtonClick}
            onClickViewReview={onClickViewReview}
            onClickRequestReview={onClickRequestReview}
            userReviewStatus={isPIP ? null : userReviewStatus}
            disabled={!selectedPeriod}
            canAddReview={canAddReview}
            canRequestFeedback={canRequestFeedback}
            onClickRejectReview={handleReject}
            canRejectReview={canRejectReview}
            onClickCancelPIP={handleCancelPipButtonClick}
            canCancelPIP={isPIP}
            canOpenPIPSettings={isPIPv2 && canViewPIPv2Settings}
            onClickOpenPIPSettings={handleOpenPipSettings}
            canRegenerateScorecards={canRegenerateScorecards}
            canDeleteProbationCycle={canDeleteProbationCycle}
            canChangeCycleSettings={canChangeCycleSettings}
            handleOpenCycleSettings={handleOpenCycleSettings}
            isProbation={isProbation}
            isProbationPeriod={isProbationPeriod}
            onClickRegenerateScorecards={handleRegenerateScorecardsButtonClick}
            onClickCloseProbation={handleCloseProbationButtonClick}
            onClickDeleteProbation={handleDeleteProbationButtonClick}
            isPerformanceReview={isNewPerformance}
          />
          {selectedPeriod && !isNewProbation && !isPIPv2 && (
            <Reviews
              fetching={fetchingRequests}
              requests={requests}
              isNewFlow={isNewPerformance}
              isPIP={isPIP}
              setFilter={setPIPScorecardFilter}
            />
          )}

          {isNewProbation && checkpoints && (
            <Box mt="s-48">
              <Flex justifyContent="space-between">
                <Text color={Color.GREY_TONE_50} fontWeight={500}>
                  Probation timeline
                </Text>

                <TimelineLink
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
                      employeeId: data.id,
                      cycleId,
                    }),
                  )}
                >
                  Open probation timeline
                </TimelineLink>
              </Flex>
              {cycleId !== undefined && (
                <Box mt="s-16">
                  <ProbationTimeline
                    checkpoints={checkpoints}
                    requests={
                      requests as PerfReviewRequestFeedbackInterface[] | undefined
                    }
                    endDate={selectedPeriod!.end_date_time}
                    employeeId={data.id}
                    cycleId={String(cycleId)}
                    reviewCategory={ReviewCategory.Probation}
                    ticketsCount={
                      jiraIntegrationEnabled
                        ? tickets && tickets.length
                        : jiraDisabledGoals && jiraDisabledGoals.length
                    }
                  />
                </Box>
              )}
            </Box>
          )}
          {pipCheckpoints && (
            <Box mt="s-48">
              <Flex justifyContent="space-between">
                <Text color={Color.GREY_TONE_50} fontWeight={500}>
                  PIP timeline
                </Text>
                <TimelineLink
                  to={getLocationDescriptor(
                    pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS, {
                      employeeId: data.id,
                      cycleId,
                    }),
                  )}
                >
                  Open PIP timeline
                </TimelineLink>
              </Flex>
              <Box mt="s-16">
                <ProbationTimeline
                  checkpoints={pipCheckpoints}
                  cycleId={String(cycleId)}
                  employeeId={data.id}
                  endDate={selectedPeriod!.end_date_time}
                  requests={requests as PerfReviewRequestFeedbackInterface[] | undefined}
                  reviewCategory={ReviewCategory.PIP_V2}
                  ticketsCount={
                    jiraIntegrationEnabled
                      ? tickets && tickets.length
                      : jiraDisabledGoals && jiraDisabledGoals.length
                  }
                />
              </Box>
            </Box>
          )}
        </Widget>
        {renderNotesReviews()}
        {selectedPeriod && !isNewPerformance && !isNewProbation && !isPIPv2 && (
          <Goals
            category={selectedPeriod.category}
            reviewCycleId={cycleId}
            reviewCycleEndDate={selectedPeriod.end_date_time}
            reviewCycleStartDate={selectedPeriod.start_date_time}
            canManageGoals={canManageGoals}
            employeeId={data.id}
            onGoalsUpdated={onGoalsUpdated}
          />
        )}
        {isNewProbation &&
          cycleId !== undefined &&
          (!probationDeliverablesType ||
            !['kpi', 'goal'].includes(probationDeliverablesType)) && (
            <Box>
              {jiraIntegrationEnabled ? (
                <ProbationGoals
                  tickets={tickets as ProbationGoalInterface[] | undefined}
                  employeeId={data.id}
                  cycleId={String(cycleId)}
                  reviewCategory={ReviewCategory.Probation}
                />
              ) : (
                <ProbationGoalsJiraDisabled
                  cycleId={String(selectedPeriod?.id)}
                  employeeId={data.id}
                  reviewCategory={ReviewCategory.Probation}
                  goals={jiraDisabledGoals}
                />
              )}
            </Box>
          )}
        {isPIPv2 && cycleId !== undefined && (
          <Box>
            {jiraIntegrationEnabled ? (
              <ProbationGoals
                cycleId={String(cycleId)}
                employeeId={data.id}
                reviewCategory={ReviewCategory.PIP_V2}
                tickets={tickets as ProbationGoalInterface[] | undefined}
              />
            ) : (
              <ProbationGoalsJiraDisabled
                cycleId={String(selectedPeriod?.id)}
                employeeId={data.id}
                reviewCategory={ReviewCategory.PIP_V2}
                goals={jiraDisabledGoals}
              />
            )}
          </Box>
        )}
        {selectedPeriod?.category === ReviewCycleCategory.Probation && (
          <FormPreview<PerformanceSelector>
            title="Additional settings"
            data={selectedPeriod}
            onEdit={
              isProbationPeriod
                ? () =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.PROBATION_EXTRA_SETTINGS.GENERAL, {
                        employeeId: data.id,
                        id: selectedPeriod?.id,
                      }),
                    )
                : undefined
            }
          >
            <Widget p="12px 16px">
              <VStack space="s-16">
                {additionalSettings.map((setting, ind) => (
                  <Flex justifyContent="space-between" key={ind}>
                    <Text variant="caption" fontWeight={500} color="grey-tone-50">
                      {setting.title}
                    </Text>
                    {setting.value}
                  </Flex>
                ))}
              </VStack>
            </Widget>
          </FormPreview>
        )}
        {selectedPeriod?.category === ReviewCycleCategory.Probation &&
          canViewProbationComments && (
            <Box mt="s-16">
              <CommentsSection
                api={probationCommentsApi(cycleId)}
                disableTodolistFeature
              />
            </Box>
          )}
        {isPIPv2 && (
          <>
            <FormPreview<PerformanceSelector>
              data={selectedPeriod}
              hideEdit={() => !canViewPIPv2Settings}
              onEdit={handleOpenPipSettings}
              title="Additional settings"
            >
              <Item p={0}>
                <Item.Content>
                  <FormPreview.Item
                    field="hr_manager"
                    title="HR manager"
                    type="employee"
                  />
                </Item.Content>
              </Item>
            </FormPreview>
            {canViewPIPv2Settings && (
              <Box mt="s-16">
                <CommentsSection api={pipCommentsApi(cycleId)} disableTodolistFeature />
              </Box>
            )}
          </>
        )}
      </Content>
      {selectedPeriod && sidebarOpen === PerformanceSidebarManager.RequestOpened && (
        <RequestFeedback
          canRequest={canRequestFeedback}
          performanceCycle={selectedPeriod}
          isNewFlow={isNewFlow}
          category={(() => {
            if (isNewProbation) {
              return ReviewCategory.Probation
            }
            if (isPIPv2) {
              return ReviewCategory.PIP_V2
            }
            return ReviewCategory.Performance
          })()}
          onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
          id={data.id}
          requests={requests}
          onAddRequest={onAddRequest}
          checkpoints={isPIPv2 ? pipCheckpoints?.checkpoints : checkpoints?.checkpoints}
          fetching={fetchingRequests}
        />
      )}
      <Side>
        {selectedPeriod &&
          sidebarOpen === PerformanceSidebarManager.SummaryOpened &&
          !isNewFlow && (
            <PositionedScorecard
              editing={false}
              setEditing={setAddingPIPReview}
              requestId={query.request_id}
              editingId={query.editing_id}
              cycleId={String(selectedPeriod.id)}
              performanceType={selectedPeriod.category}
              employee={data}
              loading={scorecardLoading}
              fromDate={selectedPeriod.start_date_time}
              toDate={selectedPeriod.end_date_time}
              onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
              forcedFilter={PIPScorecardFilter}
            />
          )}
      </Side>
      {!isPIP && !isOldProbation && (
        <SummarySidebar
          isOpen={Boolean(
            selectedPeriod &&
              sidebarOpen === PerformanceSidebarManager.SummaryOpened &&
              isNewFlow,
          )}
          cycleId={cycleId !== undefined ? String(cycleId) : undefined}
          employeeId={data.id}
          employeeSeniorityId={data.seniority?.id}
          cycleName={selectedPeriod?.name}
          category={(() => {
            if (isNewProbation) {
              return ReviewCategory.Probation
            }
            if (isPIPv2) {
              return ReviewCategory.PIP_V2
            }
            return ReviewCategory.Performance
          })()}
          onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
        />
      )}
    </Wrap>
  )
}

export default PerformanceReview
