import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { TeamGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsCardTable'
import { EmployeeGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { TableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { ContributorType } from '@src/interfaces/talent/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmployeeKpisCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeKpisCardTable'

export const NoReviewsGoals = ({
  summary,
  employee,
  selectedPeriod,
}: {
  summary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
  employee: Pick<EmployeeInterface, 'id'>
  selectedPeriod: Pick<
    PerformanceSelector,
    'category' | 'probation_reviews_deliverables_type' | 'offset' | 'id'
  >
}) => {
  const [goalsStat, setGoalsStat] = useState<number>()

  const team = summary?.team
  const gradesMap = summary?.grade_label_mapping

  if (!summary || !gradesMap) {
    return null
  }

  const hasIndividualKpi = summary.reviewed_employee_type !== ContributorType.IC

  if (
    selectedPeriod.category === ReviewCycleCategory.Probation &&
    selectedPeriod.probation_reviews_deliverables_type === 'kpi'
  ) {
    return (
      <TableWrapper headerTitle="Goals" headerStat={goalsStat}>
        <EmployeeKpisCardTable
          gradesMap={gradesMap}
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      </TableWrapper>
    )
  }

  return (
    <TableWrapper
      headerTitle={hasIndividualKpi ? 'Goals' : 'Team Goals'}
      headerStat={goalsStat}
    >
      {hasIndividualKpi ? (
        <EmployeeGoalsCardTable
          gradesMap={gradesMap}
          setGoalsStat={setGoalsStat}
          noWidget
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      ) : (
        <TeamGoalsCardTable
          gradesMap={gradesMap}
          cycle={selectedPeriod}
          team={team}
          noWidget
        />
      )}
    </TableWrapper>
  )
}
