import React from 'react'
import { Avatar, Item, Group, Copyable } from '@revolut/ui-kit'
import { useGetEmployeeSettings } from '@src/api/settings'
import { DataHandlerInterface, FormPreview } from '@components/FormPreview/FormPreview'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import {
  isLoadingSectionData,
  useActiveProfileSidebar,
} from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { Contracts } from '@src/pages/EmployeeProfile/Layout/Summary/Contracts'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { WorkDetailsSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/WorkDetails/Sidebar'
import { pathToUrl } from '@src/utils/router'

type Props = {
  data: EmployeeInterface
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}

export const EmployeeInformation = ({
  data,
  primaryDataHandler,
  sectionsData,
}: Props) => {
  const { editMode, setSidebarQueryParam } = useActiveProfileSidebar()
  const { data: employeeSettings } = useGetEmployeeSettings()

  const isInternal = data?.employee_type === 'internal'
  const basicDetailsSectionLoading = isLoadingSectionData(sectionsData.name.handler)
  const organisationSectionLoading = isLoadingSectionData(
    sectionsData.organisation.handler,
  )

  return (
    <>
      <Group>
        <Item
          aria-label="Open basic details sidebar"
          onClick={() => setSidebarQueryParam('workDetails', true, 'name')}
          variant="disclosure"
          use="button"
        >
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Basic details</Item.Title>
          </Item.Content>
        </Item>
        <FormPreview<Partial<InternalOrExternalEmployee>>
          data={sectionsData.name.data}
          dataHandler={sectionsData.name.handler}
        >
          <Group>
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="first_name"
              loading={basicDetailsSectionLoading}
              title="Legal first name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="middle_name"
              loading={basicDetailsSectionLoading}
              title="Legal middle name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="last_name"
              loading={basicDetailsSectionLoading}
              title="Legal last name"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="email"
              insert={d =>
                d.email ? (
                  <Copyable
                    value={d.email}
                    labelButtonCopy="Copy employee email"
                    onClick={() => onCopyFieldNotification('Email')}
                  >
                    {d.email}
                  </Copyable>
                ) : (
                  '-'
                )
              }
              loading={basicDetailsSectionLoading}
              title="Work email"
            />
          </Group>
        </FormPreview>
      </Group>

      <Contracts data={data} />

      <Group>
        <Item
          aria-label="Open Organisation details sidebar"
          onClick={() => setSidebarQueryParam('workDetails', true, 'organisation')}
          variant="disclosure"
          use="button"
        >
          <Item.Avatar>
            <Avatar useIcon="Services" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Organisation details</Item.Title>
          </Item.Content>
        </Item>
        <FormPreview<Partial<InternalOrExternalEmployee>>
          data={sectionsData.organisation.data}
          dataHandler={sectionsData.organisation.handler}
        >
          <Group>
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="team.name"
              to={d => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: d.team?.id })}
              loading={organisationSectionLoading}
              title="Team"
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="line_manager.name"
              insert={d =>
                d.line_manager?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.line_manager.id}
                    full_name={d.line_manager.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title={isInternal ? 'Line manager' : 'Point of contact 1'}
            />
            <FormPreview.Item<Partial<InternalOrExternalEmployee>>
              field="quality_control.name"
              insert={d =>
                d.quality_control?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.quality_control.id}
                    full_name={d.quality_control.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title={isInternal ? 'Functional manager' : 'Point of contact 2'}
            />
            <FormPreview.Item<EmployeeInterface>
              field="mentor.name"
              hidden={!isInternal || !employeeSettings?.enable_buddy}
              insert={d =>
                d.mentor?.id ? (
                  <EmployeeUserWithAvatar
                    id={d.mentor.id}
                    full_name={d.mentor.display_name}
                  />
                ) : (
                  '-'
                )
              }
              loading={organisationSectionLoading}
              title="Buddy"
            />
          </Group>
        </FormPreview>
      </Group>

      {editMode && (
        <WorkDetailsSidebar
          data={data}
          dynamicGroups={[]}
          onClose={() => setSidebarQueryParam('workDetails', false)}
          primaryDataHandler={primaryDataHandler}
          sectionsData={sectionsData}
        />
      )}
    </>
  )
}
