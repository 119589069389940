import { CandidatesListInterface, InterviewType } from '@src/interfaces/interviewTool'
import { ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import {
  candidateActionColumn,
  candidateInterviewerColumn,
  candidateInterviewStageColumn,
  candidateLastActivityDateColumn,
  candidateNameColumn,
  candidateNextInterviewColumn,
  candidateOfferApprovalStatusColumn,
  candidateOfferLocationColumn,
  candidateOfferStartDateColumn,
  candidateOfferTeamColumn,
  candidateOriginColumn,
  candidateRecruiterColumn,
  candidateSeniorityColumn,
  candidateSnoozedUntilDateColumn,
  candidateSpecialisationColumn,
  candidateStateColumn,
  candidateStatusColumn,
  candidateTagsColumn,
  candidateWorkExperienceColumn,
  candidateYearsOfExperienceColumn,
  createCandidateRequisitionColumn,
} from '@src/constants/columns/candidates'
import { selectorKeys } from '@src/constants/api'
import { Token } from '@revolut/ui-kit'
import { getSelectCellConfig } from '@components/TableV2/AdvancedCells/SelectCell/SelectCell'
import { getSelectCellConfig as getSelectCellConfigV1 } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { CandidatesTableType } from '@src/features/CommonCandidatesTable/types'

export const candidatesTableRow = ({
  showAssignedToMe,
  showSnoozedUntil,
  showStatus,
  stageType,
  type,
  isConfidential,
  isNewTable,
}: {
  showAssignedToMe: boolean
  showStatus: boolean
  showSnoozedUntil: boolean
  type: CandidatesTableType
  isConfidential?: boolean
  stageType?: InterviewType
  isNewTable?: boolean
}): RowInterface<CandidatesListInterface> => {
  const columns = {
    name: {
      ...candidateNameColumn,
      width: 250,
      filterKey: type === 'cvScreeningSummary' ? null : candidateNameColumn.filterKey,
    },
    specialisation: { ...candidateSpecialisationColumn, width: 250 },
    seniority: { ...candidateSeniorityColumn, width: 140 },
    tags: { ...candidateTagsColumn, width: 180 },
    interviewStage: { ...candidateInterviewStageColumn, width: 200 },
    status: { ...candidateStatusColumn, width: showStatus ? 200 : 250 },
    lastActivityDate: { ...candidateLastActivityDateColumn, width: 180 },
    origin: { ...candidateOriginColumn, width: 140 },
    recruiter: {
      ...candidateRecruiterColumn,
      filterKey: showAssignedToMe ? null : 'recruiter',
      selectorsKey: showAssignedToMe ? selectorKeys.none : selectorKeys.employee,
      width: showStatus ? 160 : 210,
    },
    startDate: { ...candidateOfferStartDateColumn, width: 140 },
    company: { ...candidateWorkExperienceColumn, width: 210 },
    yearsOfExperience: { ...candidateYearsOfExperienceColumn, width: 140 },
    location: { ...candidateOfferLocationColumn, width: 140 },
    team: { ...candidateOfferTeamColumn, width: 140 },
    approvalStatus: { ...candidateOfferApprovalStatusColumn, width: 140 },
    interviewer: { ...candidateInterviewerColumn, width: 140 },
    nextInterviewer: { ...candidateNextInterviewColumn, width: 140 },
    action: { ...candidateActionColumn, width: showStatus ? 160 : 210 },
    state: { ...candidateStateColumn, width: 150 },
    requisition: { ...createCandidateRequisitionColumn(isConfidential), width: 200 },
    snoozedUntilDate: { ...candidateSnoozedUntilDateColumn, width: 140 },
  }
  let cells: ColumnCellInterface<CandidatesListInterface>[] = []

  switch (stageType) {
    case 'offer': {
      cells = [
        columns.name,
        columns.lastActivityDate,
        columns.recruiter,
        columns.specialisation,
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.tags,
        columns.startDate,
        columns.location,
        columns.team,
        columns.status,
        columns.approvalStatus,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }
    default: {
      cells = [
        columns.name,
        ...(type !== 'specialisation' && type !== 'requisition'
          ? [columns.specialisation]
          : []),
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.interviewStage,
        columns.tags,
        columns.status,
        columns.lastActivityDate,
        columns.origin,
        columns.recruiter,
        ...(stageType === 'cv_screening'
          ? []
          : [columns.interviewer, columns.nextInterviewer]),
        columns.action,
        ...(showStatus ? [columns.state] : []),
        columns.requisition,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }
  }
  return {
    highlight: data =>
      data.active_interview_round?.state === 'archived' ? Token.color.greyTone5 : '',
    cells: [
      isNewTable ? { ...getSelectCellConfig() } : { ...getSelectCellConfigV1() },
      ...cells,
    ],
  }
}
