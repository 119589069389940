import React from 'react'

import { Tag, Text, Color, Token, Ellipsis } from '@revolut/ui-kit'
import { IdAndName, Statuses } from '@src/interfaces'
import { IdStatuses } from '@src/interfaces/employees'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { getStatusColor as getLegacyStatusColor } from '@components/CommonSC/General'
import { useIsNewTable } from '@components/TableV2/hooks'
import { QueryStatuses } from '@src/interfaces/dataAnalytics'
import { InterviewFeedbackStatus, InterviewStatuses } from '@src/interfaces/interviewTool'

type Status =
  | Statuses
  | IdStatuses
  | ApprovalStatuses
  | InterviewStatuses
  | InterviewFeedbackStatus
  | QueryStatuses

interface StatusCellProps {
  status: IdAndName<Status>
}

const getStatusColor = (status: Status): { color: Color; bg: Color } => {
  switch (status) {
    case Statuses.opened:
    case Statuses.open:
    case Statuses.active:
      return { color: Token.color.success, bg: Token.color.teal_20 }

    case Statuses.preapproved:
    case Statuses.funcapproved:
    case Statuses.extended:
    case Statuses.applied:
      return { color: Token.color.yellow_70, bg: Token.color.greyTone8 }

    case Statuses.pending:
    case Statuses.pending_approval:
    case Statuses.pending_reapproval:
    case Statuses.pending_completion:
    case Statuses.planned:
    case IdStatuses.onboarding:
    case Statuses.requires_changes:
    case Statuses.at_risk:
    case InterviewStatuses.pending_scheduling:
    case InterviewStatuses.pending_candidate_response:
    case InterviewStatuses.pending_candidate_signature:
    case InterviewStatuses.awaiting_interviewer_availability:
    case InterviewStatuses.awaiting_feedback:
    case InterviewStatuses.pending_cv_screening:
    case InterviewFeedbackStatus.pending:
    case InterviewFeedbackStatus.draft:
      return { color: Token.color.warning, bg: Token.color.orange_10 }

    case Statuses.closed:
    case Statuses.revoked:
    case Statuses.rejected:
    case Statuses.cancelled:
    case Statuses.canceled:
    case Statuses.terminated:
    case Statuses.delayed:
    case InterviewStatuses.interviewer_rejected:
    case InterviewStatuses.scheduling_expired:
    case InterviewStatuses.interview_cancelled:
    case InterviewFeedbackStatus.rejected:
      return { color: Token.color.danger, bg: Token.color.inputError }

    case Statuses.archived:
    case Statuses.expired:
    case Statuses.inactive:
    case Statuses.draft:
    case Statuses.future:
      return { color: Token.color.greyTone50, bg: Token.color.greyTone10 }

    case Statuses.unassigned:
      return { color: Token.color.greyTone50, bg: Token.color.greyTone8 }

    default:
      return { color: Token.color.foreground, bg: Token.color.greyTone8 }
  }
}

export const StatusCell = ({ status }: StatusCellProps) => {
  const isNewTable = useIsNewTable()

  if (!isNewTable) {
    return <Text color={getLegacyStatusColor(status.id)}>{status.name}</Text>
  }

  const statusColor = getStatusColor(status.id)

  return (
    <Tag
      variant="faded"
      color={statusColor.color}
      bg={statusColor.bg}
      style={{ maxWidth: '100%' }}
    >
      <Ellipsis>{status.name}</Ellipsis>
    </Tag>
  )
}
