import React from 'react'
import {
  DetailsCell,
  DetailsCellSkeleton,
  Icon,
  Text,
  TextSkeleton,
  TextWidget,
  TextWidgetSkeleton,
  Token,
} from '@revolut/ui-kit'
import { Grid } from '@src/components/CommonSC/Grid'
import HTMLContent from '@components/HTMLContent/HTMLContent'
import { Video as VideoGenericPlayer } from '@components/Video/Video'
import { formatWithoutTimezone } from '@src/utils/format'

interface StartPageViewProps {
  joiningDateTime?: string
  isLoading: boolean
  isLoadingBasicInfo: boolean
  title?: string | null
  text?: string | null
  name?: string
  videoUrl?: string | null
}

interface JoiningDateTimeWidgetProps {
  joiningDateTime?: string
  isLoadingBasicInfo: boolean
}

const JoiningDateWidget = ({
  joiningDateTime,
  isLoadingBasicInfo,
}: JoiningDateTimeWidgetProps) => {
  if (isLoadingBasicInfo) {
    return <DetailsCellSkeleton />
  }
  return (
    <>
      {joiningDateTime ? (
        <DetailsCell>
          <DetailsCell.Title alignSelf="center">
            <Text color={Token.color.foreground} variant="primary">
              Your expected joining date as per our records is{' '}
              {formatWithoutTimezone(joiningDateTime)}
            </Text>
          </DetailsCell.Title>
          <DetailsCell.Content alignSelf="center">
            <Icon color={Token.color.blue} name="CalendarDate" />
          </DetailsCell.Content>
        </DetailsCell>
      ) : null}
    </>
  )
}

export const StartPageView = ({
  joiningDateTime,
  isLoading,
  isLoadingBasicInfo,
  name,
  title,
  text,
  videoUrl,
}: StartPageViewProps) => {
  if (isLoading) {
    ;<Grid gap={32} pb="s-24" pt="s-12">
      <TextSkeleton variant="h1" />
      <TextWidgetSkeleton />
      <JoiningDateWidget
        joiningDateTime={joiningDateTime}
        isLoadingBasicInfo={isLoadingBasicInfo}
      />
    </Grid>
  }

  return (
    <Grid gap={32} pb="s-24" pt="s-12">
      {title && <Text variant="h1">{title}</Text>}

      <VideoGenericPlayer url={videoUrl} />

      {text && (
        <TextWidget>
          {isLoadingBasicInfo ? (
            <TextWidgetSkeleton.Title />
          ) : name ? (
            <TextWidget.Title>Dear {name}</TextWidget.Title>
          ) : null}
          <TextWidget.Content>
            <HTMLContent html={text} />
          </TextWidget.Content>
        </TextWidget>
      )}

      <JoiningDateWidget
        joiningDateTime={joiningDateTime}
        isLoadingBasicInfo={isLoadingBasicInfo}
      />
    </Grid>
  )
}
