import React from 'react'
import { connect } from 'lape'
import { Text, TextProps, Radio, RadioProps, VStack, Flex } from '@revolut/ui-kit'

export interface NewRadioProps extends Omit<RadioProps, 'label'> {
  label?: React.ReactNode
  labelProps?: TextProps
  disabled?: boolean
  description?: React.ReactNode
  leftSide?: React.ReactNode
}
const defaultProps: Partial<NewRadioProps> = {
  label: '',
  labelProps: {},
}

const NewRadio = (props: NewRadioProps) => {
  const { label, labelProps, description, leftSide, ...radioProps } = props

  return (
    <Radio type="checkbox" {...radioProps}>
      <Flex gap="s-16">
        {leftSide && leftSide}
        <VStack>
          <Radio.Label>
            <Text {...labelProps}>{label}</Text>
          </Radio.Label>
          {description && <Radio.Description>{description}</Radio.Description>}
        </VStack>
      </Flex>
    </Radio>
  )
}

NewRadio.defaultProps = defaultProps
export default connect(NewRadio)
