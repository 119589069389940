import {
  ActionButton,
  Button,
  Header,
  InputGroup,
  Popup,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import {
  performanceSettings,
  useGetCustomScorecardSections,
  useGetPerformanceSettings,
} from '@src/api/performanceSettings'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { PageActions } from '@src/components/Page/PageActions'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OptionInterface } from '@src/interfaces/selectors'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import { EditQuestion } from '@src/pages/Settings/Performance/Scorecards/EditQuestion'
import { pathToUrl } from '@src/utils/router'
import React, { useEffect, useState } from 'react'
import { InnerRoute } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/common/InnerRoute'
import { useParams } from 'react-router'
import produce from 'immer'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'

const CustomQuestionsFormContent = () => {
  const { id } = useParams<{ id?: string }>()
  const { values, submit } = useLapeContext<PerformanceSettingsInterface>()
  const [sectionIdx, setSectionIdx] = useState<number | null>()
  const [deletePending, setDeletePending] = useState(false)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)

  const { data: settings } = useGetPerformanceSettings()
  const showForFetchContext = useFetchOptions<OptionInterface<string>>(
    selectorKeys.custom_scorecard_sections_show_options,
  )

  const { data, isLoading } = useGetCustomScorecardSections()
  const sections = data?.custom_scorecard_sections

  const { showError, showSuccess } = useSubmitFlowHelpers()

  const showForOptions = settings
    ? showForFetchContext.options.filter(option => {
        const isPerformanceOption = [
          'performance_scorecards',
          'performance_fm_scorecards',
          'performance_lm_scorecards',
          'performance_self_scorecards',
          'performance_peer_scorecards',
        ].includes(option.value.id)

        const fmRelatedOptions = [
          'performance_fm_scorecards',
          'probation_fm_scorecards',
          'pip_fm_scorecards',
        ]

        return (
          isPerformanceOption &&
          (settings.enable_functional_management
            ? true
            : !fmRelatedOptions.includes(String(option.value.id)))
        )
      })
    : []

  useEffect(() => {
    if (!isLoading) {
      if (id) {
        values.custom_scorecard_sections = sections
        const currentSectionIndex = sections?.findIndex(
          section => section.id === Number(id),
        )
        setSectionIdx(currentSectionIndex)
      } else {
        values.custom_scorecard_sections = sections || []

        const newSection = {
          title: '',
          description: '',
          questions: [],
          show_for: null,
        }

        values.custom_scorecard_sections.push(newSection)

        setSectionIdx(values.custom_scorecard_sections.length - 1)
      }
    }
  }, [sections, isLoading, id])

  const section =
    typeof sectionIdx === 'number' ? values.custom_scorecard_sections?.[sectionIdx] : null

  if (!section && !deletePending) {
    return null
  }

  const onAddQuestion = () => {
    section?.questions.push({ question_type: null, options: [] })
  }

  const onDelete = async () => {
    setDeletePending(true)
    try {
      values.custom_scorecard_sections = produce(
        values.custom_scorecard_sections,
        draft => {
          draft?.splice(sectionIdx!, 1)
        },
      )
      await submit()
      showSuccess('Successfully deleted custom question', undefined, () => {
        navigateTo(pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD))
      })
    } catch (err) {
      const errorMsg = getStringMessageFromError(err)
      showError('Could not delete custom question', errorMsg)
    } finally {
      setDeletePopupOpen(false)
      setDeletePending(false)
    }
  }

  const backUrl = pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD)

  return typeof sectionIdx === 'number' ? (
    <InnerRoute
      title={`${id ? 'Edit' : 'Add'} custom question`}
      description="Define custom questions you want to have in the performance scorecards"
    >
      {id && (
        <>
          <ActionButton
            variant="negative"
            mb="s-16"
            useIcon="Delete"
            pending={deletePending}
            onClick={() => setDeletePopupOpen(true)}
          >
            Delete
          </ActionButton>
          <Popup
            open={deletePopupOpen}
            onClose={() => setDeletePopupOpen(false)}
            size="sm"
          >
            <Header variant="item">
              <Header.CloseButton aria-label="Close" />
              <Text variant="h5">
                Are you sure you want to delete this custom question?
              </Text>
              <Header.Description>This action can not be undone</Header.Description>
            </Header>

            <Popup.Actions horizontal>
              <Button variant="secondary" onClick={() => setDeletePopupOpen(false)}>
                Cancel
              </Button>
              <Button variant="negative" onClick={onDelete} pending={deletePending}>
                Yes, delete
              </Button>
            </Popup.Actions>
          </Popup>
        </>
      )}
      <VStack>
        <CellWithItem
          icon="InfoOutline"
          title="Section info"
          description="Create the title, description and define visibility"
        >
          <InputGroup>
            <LapeNewInput
              name={`custom_scorecard_sections.${sectionIdx}.title`}
              label="Title"
              required
            />
            <LapeNewInput
              name={`custom_scorecard_sections.${sectionIdx}.description`}
              label="Description"
            />
            <LapeNewMultiSelect
              placeholder="Show for"
              name={`custom_scorecard_sections.${sectionIdx}.show_for`}
              required
              options={showForOptions}
            />
          </InputGroup>
        </CellWithItem>
        {section?.questions.map((_, questionIdx) => (
          <EditQuestion
            key={questionIdx}
            sectionIdx={sectionIdx}
            questionIdx={questionIdx}
          />
        ))}
        <ActionButton useIcon="Plus" onClick={onAddQuestion} mt="s-16" mb="s-40">
          Add question
        </ActionButton>
      </VStack>
      <PageActions>
        <Button variant="secondary" onClick={() => navigateReplace(backUrl)}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup useValidator afterSubmitUrl={backUrl}>
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </InnerRoute>
  ) : null
}

export const CustomQuestionsForm = () => {
  return (
    <Form
      api={performanceSettings}
      forceParams={{ id: '1' }}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
    >
      <CustomQuestionsFormContent />
    </Form>
  )
}
