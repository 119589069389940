import React, { useEffect, useState, Fragment } from 'react'
import {
  Box,
  Cell,
  Item,
  ItemSkeleton,
  Subheader,
  ActionButton,
  Token,
  TopNav,
  Popover,
} from '@revolut/ui-kit'
import { format } from 'date-fns'
import { useQueryClient } from 'react-query'
import { GroupedVirtuoso } from 'react-virtuoso'
import { useTable } from '@components/Table/hooks'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  getSystemNotifications,
  markNotificationAsRead,
  dismissNotification,
  markAllNotificationsAsRead,
} from '@src/api/systemNotifications'
import { SystemNotificationInterface } from '@src/interfaces/systemNotifications'
import { NotificationsListItem } from '@src/features/Notifications/NotificationsListItem'
import produce from 'immer'
import { NotificationAction } from '@src/interfaces/notificationTemplate'
import { EditorView } from '@components/Editor/EditorView'

import { useOpenNewTab } from '@src/actions/RouterActions'
import { API } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface NotificationsListProps {
  scrollRef?: React.RefObject<HTMLDivElement>
  onSetNotification: (data: SystemNotificationInterface) => void
  selectedNotification?: SystemNotificationInterface
  isCoreNavigation: boolean
}

export const NotificationsList = ({
  scrollRef,
  onSetNotification,
  selectedNotification,
  isCoreNavigation,
}: NotificationsListProps) => {
  const [currentGroupCounts, setCurrentGroupCounts] = useState<number[]>([])
  const [currentGroups, setCurrentGroups] = useState<string[]>([])
  const [initialScroll, setInitialScroll] = useState(0)

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const queryClient = useQueryClient()

  // TODO: https://revolut.atlassian.net/browse/REVCOR-2712 revert after infra is fixed
  const invalidateUnreadNotifications = () => {
    if (isCommercial) {
      queryClient.invalidateQueries(`${API.SYSTEM_NOTIFICATIONS}/unread`)
    }
  }

  const openActionUrl = useOpenNewTab()

  const sortBy = [
    {
      sortBy: 'creation_date',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<SystemNotificationInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getSystemNotifications(requestData),
    },
    undefined,
    sortBy,
  )

  const getGroup = (item: SystemNotificationInterface) => {
    const date = new Date(item.creation_date)
    return format(date, 'd MMMM yyyy')
  }

  const updateGroups = (items: SystemNotificationInterface[]) => {
    const groups: string[] = []
    const groupCounts: number[] = []
    for (const item of items) {
      const groupName = getGroup(item)
      /** push a group name to array if it doesn't exist (list is ordered so checking if it doesn't match latest is enough) */
      if (groups.at(-1) !== groupName) {
        groups.push(groupName)
      }
      /**
       * Increase count of items by one for current group.
       * If it's first item in a group then "groupCounts[groups.length - 1]" will be undefined, so we use 0 instead and add 1 to it.
       */
      groupCounts[groups.length - 1] = (groupCounts[groups.length - 1] || 0) + 1
    }

    setCurrentGroupCounts(groupCounts)
    setCurrentGroups(groups)
  }

  useEffect(() => {
    updateGroups(table.data)
  }, [table.data])

  const getCount = (end: number) => {
    return currentGroupCounts.slice(0, end).reduce((a, b) => a + b, 0)
  }

  const markAsRead = async (notification: SystemNotificationInterface) => {
    if (notification.status === 'read') {
      return
    }
    table.setData(
      produce(table.data, draft => {
        const row = draft.find(item => item.id === notification.id)
        if (row) {
          row.status = 'read'
        }
      }),
    )

    markNotificationAsRead(notification.id).finally(() => {
      invalidateUnreadNotifications()
    })
  }

  const dismiss = (notificationId: number) => {
    table.setData(table.data.filter(row => row.id !== notificationId))
    dismissNotification(notificationId)
      .catch(() => {
        table.refresh()
      })
      .finally(() => {
        invalidateUnreadNotifications()
      })
  }

  const handleClick = async (notification: SystemNotificationInterface) => {
    await markAsRead(notification)

    if (notification.action === NotificationAction.url && notification.action_url) {
      openActionUrl(notification.action_url)
      return
    }
    const scroll = Math.max((scrollRef?.current?.scrollTop || 0) - 96, 0)
    setInitialScroll(scroll)

    onSetNotification(notification)
  }

  if (selectedNotification) {
    return (
      <Cell>
        <EditorView html={selectedNotification.description} />
      </Cell>
    )
  }

  const markAllAsRead = () => {
    table.setData(
      produce(table.data, draft => {
        draft.forEach(row => {
          row.status = 'read'
        })
      }),
    )

    markAllNotificationsAsRead()
      .catch(() => {
        table.refresh()
      })
      .finally(() => {
        invalidateUnreadNotifications()
      })
  }

  return (
    <TopNavWrapper
      showMarkAllAsRead={table.count > 0}
      onMarkAllAsRead={markAllAsRead}
      isCoreNavigation={isCoreNavigation}
    >
      {!isCoreNavigation && table.count > 0 && (
        <ActionButton useIcon="Check" onClick={markAllAsRead}>
          Mark all as read
        </ActionButton>
      )}
      <GroupedVirtuoso
        useWindowScroll
        initialScrollTop={initialScroll}
        customScrollParent={scrollRef?.current || undefined}
        endReached={table.fetchNextPage}
        overscan={1000}
        groupCounts={currentGroupCounts}
        groupContent={index => (
          <Box pt={index === 0 ? 0 : 's-16'}>
            <Subheader variant="nested">
              <Subheader.Title>{currentGroups[index]}</Subheader.Title>
            </Subheader>
          </Box>
        )}
        components={{
          TopItemList: Fragment,
        }}
        itemContent={(index, groupIndex) => {
          const item = table.data[index]
          if (!item) {
            return null
          }

          return (
            <Box
              backgroundColor={Token.color.widgetBackground}
              borderRadius={`${index === getCount(groupIndex) ? '12px 12px' : '0 0'} ${
                index + 1 === getCount(groupIndex + 1) ? '12px 12px ' : '0 0 '
              }`}
              mx={isCoreNavigation ? '-s-16' : 's-0'}
              key={item.id}
            >
              <NotificationsListItem
                notification={item}
                onNotificationClick={() => handleClick(item)}
                onDismiss={dismiss}
                markAsRead={markAsRead}
              />
            </Box>
          )
        }}
      />
      {table.loading && <ItemSkeleton data-testid="item-skeleton" />}
      {!table.loading && table.count === 0 && (
        <Item useIcon="InfoOutline" mx={isCoreNavigation ? '-s-16' : 's-0'}>
          <Item.Content>
            <Item.Title color="grey-tone-50">No notifications</Item.Title>
          </Item.Content>
        </Item>
      )}
    </TopNavWrapper>
  )
}

interface TopNavWrapperProps {
  isCoreNavigation: boolean
  showMarkAllAsRead: boolean
  onMarkAllAsRead: () => void
}

const TopNavWrapper: React.FC<TopNavWrapperProps> = ({
  isCoreNavigation,
  showMarkAllAsRead,
  onMarkAllAsRead,
  children,
}) => {
  if (isCoreNavigation) {
    return (
      <TopNav.Notifications
        action={
          showMarkAllAsRead && (
            <Popover.Action onClick={onMarkAllAsRead}>Mark all as read</Popover.Action>
          )
        }
      >
        {children}
      </TopNav.Notifications>
    )
  }

  return <>{children}</>
}
