import React, { ReactNode, useEffect } from 'react'
import { connect, useLape } from 'lape'
import { Flex, Box, TextProps, Color, Group, Item, Text, Token } from '@revolut/ui-kit'
import NewRadio, { NewRadioProps } from '@components/Inputs/NewRadio/NewRadio'
import { OptionInterface } from '@src/interfaces/selectors'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import isNil from 'lodash/isNil'
import Tooltip from '@components/Tooltip/Tooltip'
import { InfoOutline } from '@revolut/icons'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

const renderAsCell = (
  radio: React.ReactNode,
  options: { disabled?: boolean; tooltip?: string },
  cellBackgroundColor: string,
  onClick: () => void,
) => {
  return (
    <Box
      py="s-16"
      px="s-16"
      borderRadius={12}
      backgroundColor={cellBackgroundColor}
      flex="1"
      opacity={options.disabled ? 0.6 : 1}
      display="flex"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <Flex>
        {radio}
        {options.tooltip && (
          <Tooltip placement="top" text={options.tooltip}>
            <Text pl="s-6">
              <InfoOutline size={14} color="grey-tone-50" />
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Box>
  )
}

const renderAsItem = (
  radio: React.ReactNode,
  options: { disabled?: boolean; tooltip?: string; pressed?: boolean },
) => {
  return (
    <Item disabled={options.disabled} aria-pressed={options.pressed}>
      {radio}
      {options.tooltip && (
        <Tooltip placement="top" text={options.tooltip}>
          <Text pl="s-6">
            <InfoOutline size={14} color="grey-tone-50" />
          </Text>
        </Tooltip>
      )}
    </Item>
  )
}

export interface RadioOption
  extends RadioSelectOption<any>,
    Pick<NewRadioProps, 'disabled' | 'description' | 'leftSide'> {
  tooltip?: string
}

interface ContainerProps {
  'data-name'?: string
}

export interface NewRadioButtonsProps extends ContainerProps {
  options: RadioOption[]
  labelProps?: TextProps | ((option: RadioOption) => TextProps)
  value?: RadioOption | OptionInterface | null
  disabled?: boolean
  defaultOptionIndex?: number
  onChange?: (option: RadioOption, optionIndex: number) => void
  renderRadio?: (radio: React.ReactNode) => React.ReactNode
  readOnly?: boolean
  hasError?: boolean
  message?: ReactNode
  variant?: 'regular' | 'cell' | 'group-items'
  direction?: 'column' | 'row'
  resetDisabledOptions?: boolean
  label?: string
  highlightSelected?: boolean
  cellBackgroundColor?: string
}

const NewRadioButtons = ({
  defaultOptionIndex,
  options,
  labelProps = {},
  onChange = () => {},
  value,
  disabled,
  renderRadio = radio => radio,
  readOnly,
  message,
  hasError,
  variant = 'regular',
  direction,
  resetDisabledOptions = false,
  label,
  highlightSelected = false,
  cellBackgroundColor = Token.color.greyTone8,
  ...containerProps
}: NewRadioButtonsProps) => {
  const state = useLape({
    selectedOptionIndex: defaultOptionIndex,
  })

  const isCurrentOption = (option: RadioOption): boolean => {
    if (isNil(option.value?.id)) {
      return option.value === value
    }
    return option.value?.id === (value as OptionInterface)?.id
  }

  useEffect(() => {
    if (value === undefined || value === null) {
      return
    }

    const index = options.findIndex(option => {
      return isCurrentOption(option)
    })

    if (index === -1) {
      // case when options are not loaded yet
      return
    }
    if (options[index].disabled && resetDisabledOptions) {
      state.selectedOptionIndex = 0
      onChange(options[0], 0)
      return
    }
    if (state.selectedOptionIndex !== index) {
      state.selectedOptionIndex = index
    }
  }, [value, options])

  const isCellVariant = variant === 'cell'
  const isGroupItemsVariant = variant === 'group-items'
  const render = (() => {
    if (isCellVariant) {
      return renderAsCell
    }
    if (isGroupItemsVariant) {
      return renderAsItem
    }
    return renderRadio
  })()
  const display = isCellVariant ? undefined : 'contents'

  const onOptionChange = (option: RadioOption, index: number) => {
    return readOnly
      ? () => {}
      : () => {
          state.selectedOptionIndex = index
          onChange!(option, index)
        }
  }

  return (
    <Box display={display} {...containerProps} data-name={undefined}>
      {label && (
        <Text use="p" variant="h6" color={Color.GREY_TONE_50} mb="s-8">
          {label}
        </Text>
      )}
      <Flex
        display={display}
        gap={isCellVariant ? 's-8' : null}
        flexDirection={direction ?? undefined}
        use={isGroupItemsVariant ? Group : undefined}
      >
        {options.map((option, index) => (
          <React.Fragment key={index}>
            {render(
              <NewRadio
                description={option.description}
                leftSide={option.leftSide}
                key={typeof option.label === 'string' ? option.label : index}
                checked={index === state.selectedOptionIndex}
                label={option.label}
                labelProps={
                  typeof labelProps === 'function' ? labelProps(option) : labelProps
                }
                disabled={disabled || option.disabled}
                onChange={onOptionChange(option, index)}
                data-name={index === 0 ? containerProps['data-name'] : undefined}
              />,
              {
                disabled: disabled || option.disabled,
                tooltip: option.tooltip,
                pressed: highlightSelected && index === state.selectedOptionIndex,
              },
              cellBackgroundColor,
              onOptionChange(option, index),
            )}
          </React.Fragment>
        ))}
      </Flex>
      <BottomInputMessage hasError={hasError} message={message} />
    </Box>
  )
}

export default connect(NewRadioButtons)
