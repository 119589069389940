import React, { PropsWithChildren } from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CommonCardProps } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Id, Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getKpiGradeColumn,
  kpiCalibratedPerformanceColumn,
  kpiGenericNameColumn,
  kpiOwnerColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface, NameIdInterface } from '@src/interfaces/employees'
import { GradesMapInterface } from '@src/utils/grades'
import isNumber from 'lodash/isNumber'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useTable } from '@components/Table/hooks'
import { kpisRequests } from '@src/api/kpis'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

interface KPIsCardInterface extends CommonCardProps {
  employee: EmployeeInterface
  gradesMap: GradesMapInterface
  setGoalsStat?: React.Dispatch<React.SetStateAction<number | undefined>>
  setGoalsList?: React.Dispatch<React.SetStateAction<GoalsInterface[] | undefined>>
  noWidget?: boolean
}

export const getKpisRow: (
  gradesMap: GradesMapInterface,
  goalsEnabled: boolean,
  noWidget?: boolean,
) => RowInterface<KpiInterface> = (gradesMap, goalsEnabled, noWidget) => ({
  disabled: data => data.target_status === Statuses.archived,
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })),
  cells: [
    {
      ...kpiGenericNameColumn,
      title: goalsEnabled ? 'Metric name' : 'KPI name',
      width: noWidget ? 150 : 300,
    },
    {
      ...kpiWeightColumn,
      width: noWidget ? 80 : 60,
    },
    {
      ...kpiOwnerColumn,
      width: 80,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      title: 'Progress',
      width: 100,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})

type TableWrapperProps = PropsWithChildren<{
  noWidget?: boolean
}>

export const TableWrapper = ({ noWidget, children }: TableWrapperProps) => {
  return noWidget ? (
    <>{children}</>
  ) : (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>{children}</TableWidget.Table>
    </TableWidget>
  )
}

const KPIsCardTableContent = ({
  employee,
  gradesMap,
  setGoalsStat,
  noWidget,
}: KPIsCardInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycleId = 'reviews' in values ? values.reviews[0].cycle?.id : values.cycle?.id
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const initialFilter = [
    {
      columnName: 'goals_related',
      filters: [
        { id: goalsEnabled ? 'true' : 'false', name: goalsEnabled ? 'true' : 'false' },
      ],
      nonResettable: true,
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_employee',
      nonResettable: true,
    },
    {
      filters: [{ name: employee.id.toString(), id: employee.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      columnName: 'target_status',
      nonResettable: true,
    },
    {
      columnName: 'cycle__id',
      filters: cycleId
        ? [{ name: String(cycleId), id: cycleId }]
        : [{ name: '0', id: 0 }],
      nonResettable: true,
    },
    ...(goalsEnabled
      ? [
          {
            filters: [
              { name: Statuses.pending, id: Statuses.pending },
              { name: Statuses.approved, id: Statuses.approved },
            ],
            columnName: 'goal__approval_status',
            nonResettable: true,
          },
          {
            filters: [{ name: Statuses.active, id: Statuses.active }],
            columnName: 'status',
            nonResettable: true,
          },
        ]
      : []),
  ]

  const table = useTable<KpiInterface>(kpisRequests, initialFilter, [])

  if (isNumber(table.stats?.avg_performance) && !!setGoalsStat) {
    setGoalsStat(table.stats?.avg_performance)
  }

  return (
    <TableWrapper noWidget={noWidget}>
      <AdjustableTable<KpiInterface>
        name={TableNames.EmployeePerformanceKPI}
        dataType={goalsEnabled ? 'Metrics' : 'KPI'}
        row={getKpisRow(gradesMap, goalsEnabled, noWidget)}
        {...table}
        noDataMessage={`No ${goalsEnabled ? 'metrics' : 'KPI'} defined`}
        onRowClick={onKPITableRowClick}
        noReset
        hideCountAndButtonSection
      />
    </TableWrapper>
  )
}

export interface KPIsCardTableProps
  extends Pick<KPIsCardInterface, 'gradesMap' | 'setGoalsStat' | 'setGoalsList'> {
  employeeId?: number
  team?: NameIdInterface
  cycle?: ReviewCyclesInterface | (Id<string | number> & Partial<ReviewCyclesInterface>)
  noWidget?: boolean
  reviewValues?: ReviewSummaryInterface
}

export const KPIsCardTable = connect(
  ({ gradesMap, setGoalsStat, noWidget = false }: KPIsCardTableProps) => {
    const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
    const { data: employee } = useGetEmployeeWithManagerFilter(
      'reviewed_employee' in values
        ? values.reviewed_employee.id
        : values.reviews[0].reviewed_employee.id,
      'line_manager__id',
    )

    if (!employee) {
      return null
    }

    return (
      <KPIsCardTableContent
        employee={employee}
        gradesMap={gradesMap}
        setGoalsStat={setGoalsStat}
        noWidget={noWidget}
      />
    )
  },
)
