import {
  ChatMessageInterface,
  ChatMessageStatus,
  CommentsAPIInterface,
  TaggedUsersMap,
} from '@src/interfaces/chat'
import { useFetch } from '@src/utils/reactQuery'
import { ApiVersion, GetRequestData, GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { useEffect, useState } from 'react'
import { uniqBy } from 'lodash'

export interface CommentsAPIOptions {
  baseUrl: string
  apiVersion?: ApiVersion
  isExternal?: boolean
  pageSize?: number
}

export const getCommentsAPI = ({
  baseUrl,
  apiVersion = 'v1',
  isExternal = false,
  pageSize = 100,
}: CommentsAPIOptions): CommentsAPIInterface => {
  return {
    useGetComments: (showArchived, disabled) => {
      const [page, setPage] = useState(1)
      const [allLoadedComments, setAllLoadedComments] = useState<ChatMessageInterface[]>(
        [],
      )

      const params = {
        status: showArchived
          ? undefined
          : `${ChatMessageStatus.active},${ChatMessageStatus.completed}`,
        page,
        page_size: pageSize,
      }

      const comments = useFetch<GetRequestData<ChatMessageInterface>>(
        disabled ? null : baseUrl,
        apiVersion,
        { params },
        undefined,
        undefined,
        isExternal,
      )

      useEffect(() => {
        if (comments.status !== 'success') {
          return
        }
        if (comments.data?.results) {
          setAllLoadedComments(
            uniqBy([...allLoadedComments, ...comments.data.results], 'id'),
          )
        }
      }, [comments.data?.results, comments.status])

      return {
        ...comments,
        allLoadedComments,
        loadNextPage: () => {
          const totalPages = comments.data?.pages.total || 0

          if (page < totalPages) {
            setPage(page + 1)
          }
        },
        refetchFirstPage: async () => {
          const res = await api.get<GetRequestInterface<ChatMessageInterface>>(
            baseUrl,
            { params: { ...params, page: 1 } },
            apiVersion,
            isExternal,
          )
          setAllLoadedComments(uniqBy([...res.data.results, ...allLoadedComments], 'id'))

          return res
        },
      }
    },
    useGetComment: id => {
      return useFetch<ChatMessageInterface>(
        id ? `${baseUrl}/${id}` : null,
        apiVersion,
        undefined,
        undefined,
        undefined,
        isExternal,
      )
    },
    addComment: (comment, createTask, attachedFiles) => {
      return api.post<ChatMessageInterface>(
        baseUrl,
        {
          body: comment,
          create_task: createTask,
          attached_files: attachedFiles,
        },
        undefined,
        apiVersion,
        isExternal,
      )
    },
    editComment: (commentId, updatedMsg, attachedFiles) => {
      return api.patch<ChatMessageInterface>(
        `${baseUrl}/${commentId}`,
        {
          body: updatedMsg,
          attached_files: attachedFiles,
        },
        undefined,
        apiVersion,
        isExternal,
      )
    },
    archiveComment: commentId => {
      return api.post(
        `${baseUrl}/${commentId}/archive`,
        undefined,
        undefined,
        apiVersion,
        isExternal,
      )
    },
    resolveComment: commentId => {
      return api.post(
        `${baseUrl}/${commentId}/complete`,
        undefined,
        undefined,
        apiVersion,
        isExternal,
      )
    },
  }
}

export const useGetInboxTaggedEmployees = () => {
  return useFetch<TaggedUsersMap>(`${API.NOTIFICATIONS}/taggedEmployees`, 'v2')
}
