import { FiltersSidebarItemInterface } from '@components/FiltersSidebar/FiltersSidebarItem'
import { FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { renderOptionFilterSidebarItem } from '@components/JobPostingOption/JobPostingOption'

export const candidateTableFilters: FiltersSidebarItemInterface[] = [
  {
    label: 'Candidate state',
    field: 'active_interview_round__state',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_round_states,
    icon: 'Dot',
  },
  {
    label: 'Last activity date',
    field: 'latest_event_date_time',
    filterType: FilterType.date,
    icon: 'LineChart',
  },
  {
    label: 'Confidential candidates only',
    field: 'is_confidential',
    icon: 'Anonymous',
    filterType: FilterType.boolean,
  },
  {
    label: 'Sourced by',
    field: 'active_interview_round__created_by',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: 'Profile',
  },
  {
    label: 'Main job posting',
    field: 'active_interview_round__application_forms__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.all_job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
    icon: 'Document',
  },
  {
    label: 'Hiring manager',
    field: 'hiring_manager',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: 'Profile',
  },
  {
    label: 'Archival reason',
    field: 'active_interview_round__archived_reason',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_round_archived_reasons,
    icon: 'Archive',
  },
  {
    label: 'Candidate’s current country',
    field: 'country',
    filterType: FilterType.selector,
    selector: selectorKeys.countries,
    icon: 'LocationPin',
  },
  {
    label: 'Candidate’s preferred work location',
    field: 'preferred_locations',
    filterType: FilterType.selector,
    selector: selectorKeys.location_names,
    icon: 'MapPoint',
  },
  {
    label: 'Candidate’s companies',
    field: 'work_experiences__company',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_companies,
    icon: 'Services',
  },
  {
    label: 'Candidate’s current company',
    field: 'current_work_experience__company',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_companies,
    icon: 'Services',
  },
  {
    label: 'Candidate’s titles',
    field: 'work_experiences__position',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_positions,
    icon: 'RepairTool',
  },
  {
    label: 'Candidate’s current title',
    field: 'current_work_experience__position',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_positions,
    icon: 'RepairTool',
  },
  {
    label: 'Candidate’s university',
    field: 'educations__university',
    filterType: FilterType.selector,
    selector: selectorKeys.universities,
    icon: 'Backpack',
  },
  {
    label: 'Candidate’s major',
    field: 'educations__major',
    filterType: FilterType.selector,
    selector: selectorKeys.education_majors,
    icon: '20/Groups',
  },
  {
    label: 'Candidate’s degree',
    field: 'educations',
    filterType: FilterType.selector,
    selector: selectorKeys.degrees,
    icon: 'Basketball',
  },
  {
    label: 'Candidate’s created date',
    field: 'created_date_time',
    filterType: FilterType.date,
    icon: 'TimeAndMoney',
  },
  {
    label: 'Interview round created date',
    field: 'active_interview_round__created_date_time',
    filterType: FilterType.date,
    icon: 'Time',
  },
  {
    label: 'Snoozed',
    field: 'is_snoozed',
    filterType: FilterType.selector,
    selector: selectorKeys.yes_no_uppercase_value_options,
    icon: 'Time',
  },
  {
    label: 'Snoozed date',
    field: 'active_snoozing__snooze_until',
    filterType: FilterType.shortDate,
    icon: 'Time',
  },
  {
    label: 'All job postings',
    field: 'interview_rounds__application_forms__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.all_job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
    icon: 'DocumentsPair',
  },
]
