import { Token } from '@revolut/ui-kit'
import { useTableReturnType } from '@src/components/Table/hooks'
import {
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  singleTargetAdditionalColumns,
} from '@src/constants/columns/goals'
import { ROUTES } from '@src/constants/routes'
import { rowHighlight } from '@src/features/KPI'
import { Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'

interface RowParams {
  isMultipleGoalsTargetsEnabled: boolean
  canManageGoals: boolean
  updateRows: useTableReturnType<GoalsInterface>['updateRows']
  isManagingWeights: boolean
}

export const getRows = ({
  isMultipleGoalsTargetsEnabled,
  canManageGoals,
  updateRows,
  isManagingWeights,
}: RowParams) => {
  return {
    highlight: (goal: GoalsInterface) =>
      [ApprovalStatuses.Draft, ApprovalStatuses.Archived].includes(
        goal.approval_status.id,
      )
        ? Token.color.greyTone2
        : rowHighlight(goal.approval_status.id),
    cells: [
      {
        ...goalsNameColumn(goal =>
          pathToUrl(
            goal.status.id === Statuses.draft
              ? ROUTES.FORMS.GOAL.EDIT
              : ROUTES.FORMS.GOAL.PREVIEW,
            { id: goal.id },
          ),
        ),
        width: 300,
      },
      {
        ...(isManagingWeights ? goalsInputWeightColumn : goalsWeightColumn),
        width: 70,
      },
      {
        ...goalsChangeStatusColumn(
          (status, goalId) =>
            updateRows(
              r => r.id === goalId,
              r => ({ ...r, status }),
            ),
          canManageGoals,
        ),
        width: 100,
      },
      {
        ...goalsProgressColumn,
        width: 160,
      },
      ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
      {
        ...goalsOwnerColumn,
        width: 150,
      },
      {
        ...goalsApprovalStatusColumn,
        width: 50,
      },
    ].filter(Boolean),
  }
}
