import React, { useState } from 'react'
import {
  Color,
  Text,
  Box,
  TextButton,
  BottomSheet,
  Header,
  Button,
  useStatusPopup,
  Input,
  StatusPopup,
  Token,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { subDays, format } from 'date-fns'

import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  PublicHolidayInterface,
  RegimeInterface,
  RegimeMembershipsInterface,
  EmployeeTimeOffPolicyAssignmentInterface,
  TimeOffPolicyTableInterface,
  EmployeeTimeOffBalancesInterface,
  EmployeeTimeOffRequestInterface,
  EmployeeTimeOffBalanceTransaction,
  RequestOfReportsInterface,
  TeamTimeOffRequestsInterface,
  EmployeeTimeOffRequestsCalendarInterface,
  TimeOffCategoryInterface,
} from '@src/interfaces/timeOff'
import { selectorKeys } from '../api'
import { getStatusColor } from '@src/components/CommonSC/General'
import { formatPeriod } from '@src/utils/format'
import { Statuses } from '@src/interfaces'
import { utcToLocalDate } from '@src/utils/timezones'
import { TimeOffPolicyCategory } from '@src/features/TimeOff/TimeOffPolicyCategory'
import { updateTimeOffPolicyAccrualAmount } from '@src/api/timeOff'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { arrayErrorsToFormError } from '@src/utils/form'

export const publicHolidaysNameColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.public_holidays,
  title: 'Name',
}

export const publicHolidaysRegionColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.text,
  idPoint: 'region.id',
  dataPoint: 'region.name',
  sortKey: 'region__name',
  filterKey: 'region_id',
  selectorsKey: selectorKeys.time_off_regions,
  title: 'Region',
}

export const publicHolidaysCountryColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: 'country__name',
  filterKey: 'country_id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}

export const publicHolidaysFromDateColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.dateInUtc,
  idPoint: 'from_date',
  dataPoint: 'from_date',
  sortKey: 'from_date',
  filterKey: 'from_date',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Start date',
}

export const publicHolidaysToDateColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.dateInUtc,
  idPoint: 'to_date',
  dataPoint: 'to_date',
  sortKey: 'to_date',
  filterKey: 'to_date',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'End date',
}

export const publicHolidaysCreationDateColumn: ColumnInterface<PublicHolidayInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const regimeNameColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.time_off_regimes,
  title: 'Regime name',
}

export const regimeGroupsColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.insert,
  idPoint: 'groups',
  dataPoint: 'groups',
  sortKey: 'groups',
  filterKey: 'groups',
  selectorsKey: selectorKeys.dynamic_groups,
  title: 'Groups',
  insert: ({ data }) => data.groups.map(group => group.name).join(', '),
}

export const regimeDefaultApproverColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'default_approver.id',
  dataPoint: 'default_approver.name',
  sortKey: 'default_approver',
  filterKey: 'default_approver',
  selectorsKey: selectorKeys.time_off_regime_default_approvers,
  title: 'Default approver',
}

export const regimeApproverGroupColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'default_approver_group.id',
  dataPoint: 'default_approver_group.name',
  sortKey: 'default_approver_group__name',
  filterKey: 'default_approver_group__id',
  selectorsKey: selectorKeys.dynamic_groups,
  title: 'Approver group',
}

export const regimeWorkingDaysStartColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'working_days_start.id',
  dataPoint: 'working_days_start.name',
  sortKey: 'working_days_start',
  filterKey: 'working_days_start',
  selectorsKey: selectorKeys.days_of_the_week,
  title: 'Working days start',
}

export const regimeWorkingDaysEndColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'working_days_end.id',
  dataPoint: 'working_days_end.name',
  sortKey: 'working_days_end',
  filterKey: 'working_days_end',
  selectorsKey: selectorKeys.days_of_the_week,
  title: 'Working days end',
}

export const regimePublicHolidaysColumn: ColumnInterface<RegimeInterface> = {
  type: CellTypes.text,
  idPoint: 'public_holidays.id',
  dataPoint: 'public_holidays.name',
  sortKey: 'public_holidays',
  filterKey: 'public_holidays',
  selectorsKey: selectorKeys.time_off_regime_public_holidays,
  title: 'Public holidays',
}

export const regimeMembershipEmployeeColumn: ColumnInterface<RegimeMembershipsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.name',
    sortKey: 'employee__full_name',
    filterKey: 'employee_id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const regimeMembershipCreationTimeColumn: ColumnInterface<RegimeMembershipsInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.date,
    title: 'Creation time',
  }

export const timeOffRequestNameColumn: ColumnInterface<EmployeeTimeOffRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'policy.category.id',
    dataPoint: 'policy.category.name',
    sortKey: 'balance__policy__category__name',
    filterKey: 'balance__policy__category_id',
    selectorsKey: selectorKeys.time_off_policy_categories,
    title: 'Policy',
  }

export const formatRequestDate = (value: string, unit: 'day' | 'hour') => {
  if (unit === 'day') {
    return format(utcToLocalDate(value), 'd MMM yyyy')
  }
  return (
    <Box>
      {format(new Date(value), 'd MMM yyyy')}{' '}
      <Text color={Color.GREY_TONE_50}>{format(new Date(value), 'HH:mm OOO')}</Text>
    </Box>
  )
}

export const timeOffRequestStartDateColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'from_date_time',
  dataPoint: 'from_date_time',
  sortKey: 'from_date_time',
  filterKey: 'from_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Start date',
  insert: ({ data }) => formatRequestDate(data.from_date_time!, data.unit.id),
}

export const timeOffRequestEndDateColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'to_date_time',
  dataPoint: 'to_date_time',
  sortKey: 'to_date_time',
  filterKey: 'to_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'End date',
  insert: ({ data }) => formatRequestDate(data.to_date_time!, data.unit.id),
}

export const timeOffRequestDurationColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Duration',
  insert: ({ data }) => pluralize(data.unit.name.toLowerCase(), data.duration, true),
}

export const timeOffRequestRequestedOnColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.dateTime,
  idPoint: 'requested_on',
  dataPoint: 'requested_on',
  sortKey: 'requested_on',
  filterKey: 'requested_on',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Submitted on',
}

export const timeOffRequestApproverColumn: ColumnInterface<
  EmployeeTimeOffRequestInterface | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'approver.id',
  dataPoint: 'approver.name',
  sortKey: 'approver__full_name',
  filterKey: 'approver_id',
  selectorsKey: selectorKeys.employee,
  title: 'Approver',
  insert: ({ data }) => <UserWithAvatar {...data.approver} />,
}

export const timeOffRequestApprovedOnColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.dateTime,
  idPoint: 'approval_date_time',
  dataPoint: 'approval_date_time',
  sortKey: 'approval_date_time',
  filterKey: 'approval_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Approved on',
}

export const timeOffRequestApprovalColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.text,
  idPoint: 'approval_status.id',
  dataPoint: 'approval_status.name',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.time_off_request_approval_statuses,
  title: 'Approval',
  colors: data => getStatusColor(data.approval_status.id),
}

export const timeOffRequestStatusColumn: ColumnInterface<
  | EmployeeTimeOffRequestInterface
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.time_off_request_statuses,
  title: 'Status',
}

export const timeOffPolicyAssignmentNameColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'policy.id',
    dataPoint: 'policy.name',
    sortKey: 'policy_id',
    filterKey: 'policy_id',
    selectorsKey: selectorKeys.time_off_policies,
    title: 'Name',
  }

export const timeOffPolicyAssignmentCategoryColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'policy.category.id',
    dataPoint: 'policy.category.name',
    sortKey: 'policy__category__name',
    filterKey: 'policy__category_id',
    selectorsKey: selectorKeys.time_off_policy_categories,
    title: 'Category',
    insert: ({ data }) => {
      return <TimeOffPolicyCategory category={data.policy?.category} />
    },
  }

export const timeOffPolicyAssignmentStartDateColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.dateInUtc,
    idPoint: 'start_date',
    dataPoint: 'start_date',
    sortKey: 'start_date',
    filterKey: 'start_date',
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const timeOffPolicyAssignmentEndDateColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.dateInUtc,
    idPoint: 'end_date',
    dataPoint: 'end_date',
    sortKey: 'end_date',
    filterKey: 'end_date',
    selectorsKey: selectorKeys.none,
    title: 'End date',
  }

export const timeOffPolicyAssignmentAssignedOnColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'assigned_on',
    dataPoint: 'assigned_on',
    sortKey: 'assigned_on',
    filterKey: 'assigned_on',
    selectorsKey: selectorKeys.none,
    title: 'Assigned on',
  }

export const timeOffPolicyAssignmentAssignedByColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'assigned_by',
    dataPoint: 'assigned_by',
    sortKey: 'assigned_by__full_name',
    filterKey: 'assigned_by_id',
    selectorsKey: selectorKeys.employee,
    title: 'Assigned by',
    insert: ({ data }) =>
      data.assigned_by ? <UserWithAvatar {...data.assigned_by} /> : '-',
  }

export const timeOffPolicyAssignmentStatusColumn: ColumnInterface<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.time_off_policy_assignment_statuses,
    title: 'Status',
    colors: data => getStatusColor(data.status.id),
  }

export const timeOffPolicyNameColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.time_off_policies,
  title: 'Name',
}

export const timeOffPolicyHeadcountColumn: ColumnInterface<TimeOffPolicyTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'assignment_count',
    dataPoint: 'assignment_count',
    sortKey: 'assignment_count',
    filterKey: 'assignment_count',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Members',
  }

export const timeOffPolicyGroupColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'group.id',
  dataPoint: 'group.name',
  sortKey: 'group__name',
  filterKey: 'group_id',
  selectorsKey: selectorKeys.dynamic_groups,
  title: 'Eligibility group',
}

export const timeOffPolicyCategoryColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.insert,
  idPoint: 'category.id',
  dataPoint: 'category.name',
  sortKey: 'category__name',
  filterKey: 'category_id',
  selectorsKey: selectorKeys.time_off_policy_categories,
  title: 'Category',
  insert: ({ data }) => {
    return <TimeOffPolicyCategory category={data.category} />
  },
}

export const timeOffPolicyPaidColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'paid.id',
  dataPoint: 'paid.name',
  sortKey: 'paid',
  filterKey: 'paid',
  selectorsKey: selectorKeys.time_off_policy_paid_options,
  title: 'Paid',
}

export const timeOffPolicyUnitColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'unit.id',
  dataPoint: 'unit.name',
  sortKey: 'unit',
  filterKey: 'unit',
  selectorsKey: selectorKeys.time_off_units,
  title: 'Unit',
}

export const timeOffPolicyBalanceColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'balance_type.id',
  dataPoint: 'balance_type.name',
  sortKey: 'balance_type',
  filterKey: 'balance_type',
  selectorsKey: selectorKeys.time_off_balance_types,
  title: 'Balance',
}

interface TimeOffPolicyEditableBalanceColumnProps {
  data: TimeOffPolicyTableInterface
  onSubmitSuccess: () => void
}

const TimeOffPolicyEditableBalanceColumn = ({
  data,
  onSubmitSuccess,
}: TimeOffPolicyEditableBalanceColumnProps) => {
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [value, setValue] = useState(data.accrual_amount || '')
  const [submitPending, setSubmitPending] = useState(false)
  const [inputError, setInputError] = useState<string>()

  const statusPopup = useStatusPopup()

  const isUnlimited = data.balance_type.id === 'unlimited'

  if (isUnlimited) {
    return <>{data.balance_type.name}</>
  }

  const onSubmit = () => {
    if (!value) {
      return
    }

    setSubmitPending(true)

    updateTimeOffPolicyAccrualAmount(data.id, value)
      .then(() => {
        onSubmitSuccess()
        setShowEditPopup(false)
        setSubmitPending(false)
      })
      .catch(error => {
        const submitErrors = arrayErrorsToFormError<{ accrual_amount?: string }>(
          error?.response?.data,
        )

        setSubmitPending(false)

        if (submitErrors.accrual_amount) {
          setInputError(submitErrors.accrual_amount)
        } else {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to update accrual amount</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
              <StatusPopup.Actions>
                <Button onClick={statusPopup.hide} elevated>
                  Close
                </Button>
              </StatusPopup.Actions>
            </StatusPopup>,
          )
        }
      })
  }

  return (
    <>
      <TextButton onClick={() => setShowEditPopup(true)}>
        {data.unit?.name && data.accrual_amount != null
          ? pluralize(data.unit.name.toLowerCase(), data.accrual_amount, true)
          : '-'}
      </TextButton>

      <BottomSheet open={showEditPopup} onClose={() => setShowEditPopup(false)}>
        <Header>
          <Header.Title>Adjust balance</Header.Title>
        </Header>

        <Input
          label="Accrual amount"
          value={value}
          onChange={e => {
            setValue(e.currentTarget.value)
            setInputError(undefined)
          }}
          aria-invalid={!!inputError}
          message={inputError}
        />

        <BottomSheet.Actions horizontal>
          <Button onClick={() => setShowEditPopup(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={!value} pending={submitPending} elevated>
            Confirm
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

export const timeOffPolicyEditableBalanceColumn = (
  onSubmitSuccess: () => void,
): ColumnInterface<TimeOffPolicyTableInterface> => ({
  type: CellTypes.insert,
  idPoint: 'balance_type.id',
  dataPoint: 'balance_type.name',
  sortKey: 'balance_type',
  filterKey: 'balance_type',
  selectorsKey: selectorKeys.time_off_balance_types,
  title: 'Balance',
  insert: ({ data }) => (
    <TimeOffPolicyEditableBalanceColumn data={data} onSubmitSuccess={onSubmitSuccess} />
  ),
})

export const timeOffPolicyDetailsUrlColumn: ColumnInterface<TimeOffPolicyTableInterface> =
  {
    type: CellTypes.link,
    idPoint: 'details_url',
    dataPoint: 'details_url',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Guide',
  }

export const timeOffPolicyCreatedOnColumn: ColumnInterface<TimeOffPolicyTableInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Created on',
  }

export const timeOffPolicyStatusColumn: ColumnInterface<TimeOffPolicyTableInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.time_off_policy_statuses,
  title: 'Status',
  colors: data => getStatusColor(data.status.id),
}

export const timeOffBalancePolicyNameColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: 'policy__category__name',
    filterKey: 'policy__category_id',
    selectorsKey: selectorKeys.time_off_policy_categories,
    title: 'Policy (cycle dates)',
    insert: ({ data }) => {
      const endDate = subDays(utcToLocalDate(data.cycle_end), 1).toISOString()
      const period = formatPeriod(utcToLocalDate(data.cycle_start), endDate)

      return (
        <Box>
          <TimeOffPolicyCategory category={data.policy.category} />{' '}
          <Text color={Color.GREY_TONE_50}>({period})</Text>
        </Box>
      )
    },
  }

export const formatBalanceCount = (
  data: EmployeeTimeOffBalancesInterface,
  column: 'balance_end_of_cycle_rounded' | 'balance_rounded',
) => {
  if (data.balance_type.id === 'unlimited') {
    return 'Unlimited'
  }

  const amount = data[column]
  return amount != null ? pluralize(data.unit.name.toLowerCase(), amount, true) : '-'
}

export const formatDuration = (
  data: EmployeeTimeOffBalancesInterface,
  column: 'booked' | 'taken' | 'accrued_rounded' | 'carried_over',
) => {
  const amount = data[column]
  return amount != null ? pluralize(data.unit.name.toLowerCase(), amount, true) : '-'
}

export const getBalanceColor = (
  data: EmployeeTimeOffBalancesInterface,
  property: 'balance_rounded' | 'balance_end_of_cycle_rounded',
) => {
  const balance = data[property]
  const isNegative = balance != null && balance < 0

  return isNegative
    ? Token.color.red
    : property === 'balance_rounded'
    ? Token.color.green
    : Token.color.orange
}

export const timeOffBalanceEndOfCycleCountColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'balance_end_of_cycle_rounded',
    dataPoint: 'balance_end_of_cycle_rounded',
    sortKey: 'balance_end_of_cycle_rounded',
    filterKey: 'balance_end_of_cycle_rounded',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Available (final)',
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        Number of days available to book until the end of the cycle
      </Box>
    ),
    colors: data => getBalanceColor(data, 'balance_end_of_cycle_rounded'),
    insert: ({ data }) => formatBalanceCount(data, 'balance_end_of_cycle_rounded'),
  }

export const timeOffBalanceBookedCountColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'booked',
    dataPoint: 'booked',
    sortKey: 'booked',
    filterKey: 'booked',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Booked',
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        Number of days booked and approved but not taken yet
      </Box>
    ),
    insert: ({ data }) => formatDuration(data, 'booked'),
  }

export const timeOffBalanceTakenCountColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'taken',
    dataPoint: 'taken',
    sortKey: 'taken',
    filterKey: 'taken',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Taken',
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        Number of days already taken
      </Box>
    ),
    insert: ({ data }) => formatDuration(data, 'taken'),
  }

export const timeOffBalanceAccruedCountColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'accrued_rounded',
    dataPoint: 'accrued_rounded',
    sortKey: 'accrued_rounded',
    filterKey: 'accrued_rounded',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Accrued',
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        Number of days earned in this cycle
      </Box>
    ),
    insert: ({ data }) => formatDuration(data, 'accrued_rounded'),
    colors: () => Token.color.greyTone50,
  }

export const timeOffBalanceCarriedOverCountColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'carried_over',
    dataPoint: 'carried_over',
    sortKey: 'carried_over',
    filterKey: 'carried_over',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Carried over',
    headerTooltip: (
      <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
        Number of days transferred from the previous cycle
      </Box>
    ),
    insert: ({ data }) => formatDuration(data, 'carried_over'),
    colors: () => Token.color.greyTone50,
  }

export const timeOffBalanceStatusColumn: ColumnInterface<EmployeeTimeOffBalancesInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.time_off_balance_statuses,
    title: 'Status',
    colors: data => {
      switch (data.status.id) {
        case Statuses.open:
          return Token.color.greyTone50
        case Statuses.closed:
          return Token.color.red
        case Statuses.pending:
          return Token.color.orange
        default:
          return Token.color.foreground
      }
    },
  }

export const timeOffBalanceTransactionCreateDateColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.dateTime,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Creation date',
  }

export const timeOffBalanceTransactionTypeColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.text,
    idPoint: 'source.id',
    dataPoint: 'source.name',
    sortKey: 'source',
    filterKey: 'source',
    selectorsKey: selectorKeys.time_off_balance_transaction_sources,
    title: 'Type',
  }

export const timeOffBalanceTransactionAmountColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.insert,
    idPoint: 'amount',
    dataPoint: 'amount',
    sortKey: 'amount',
    filterKey: 'amount',
    selectorsKey: selectorKeys.none,
    filterType: FilterType.range,
    title: 'Transaction',
    colors: data => (data.amount < 0 ? Token.color.red : Token.color.foreground),
    insert: ({ data }) => `${data.amount > 0 ? '+' : ''}${data.amount}`,
  }

export const timeOffBalanceTransactionFieldColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.text,
    idPoint: 'field.id',
    dataPoint: 'field.name',
    sortKey: 'field',
    filterKey: 'field',
    selectorsKey: selectorKeys.time_off_balance_transaction_fields,
    title: 'Field',
  }

export const timeOffBalanceTransactionCreatedByColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.insert,
    idPoint: 'created_by',
    dataPoint: 'created_by',
    sortKey: 'created_by__full_name',
    filterKey: 'created_by_id',
    selectorsKey: selectorKeys.employee,
    title: 'Created by',
    insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
  }

export const timeOffBalanceTransactionDescriptionColumn: ColumnInterface<EmployeeTimeOffBalanceTransaction> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }

export const requestOfReportsEmployeeColumn: ColumnInterface<
  | RequestOfReportsInterface
  | TeamTimeOffRequestsInterface
  | EmployeeTimeOffRequestsCalendarInterface
> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.name',
  sortKey: 'employee__full_name',
  filterKey: 'employee_id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
}

export const requestOfReportsTeamColumn: ColumnInterface<RequestOfReportsInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team_id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const requestOfReportsPolicyColumn: ColumnInterface<RequestOfReportsInterface> = {
  type: CellTypes.text,
  idPoint: 'policy.category.id',
  dataPoint: 'policy.name',
  sortKey: 'policy__name',
  filterKey: 'policy__id',
  selectorsKey: selectorKeys.time_off_policies,
  title: 'Policy',
}

export const teamTimeOffRequestsPolicyColumn: ColumnInterface<TeamTimeOffRequestsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'policy.category.id',
    dataPoint: 'policy.category.name',
    sortKey: 'balance__policy__name',
    filterKey: 'balance__policy_id',
    selectorsKey: selectorKeys.time_off_policy_categories,
    title: 'Policy',
    insert: ({ data }) => {
      return <TimeOffPolicyCategory category={data.policy.category} />
    },
  }

export const timeOffCategoryNameColumn: ColumnInterface<TimeOffCategoryInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.time_off_policy_categories,
  title: 'Name',
}

export const timeOffCategoryDescriptionColumn: ColumnInterface<TimeOffCategoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }
