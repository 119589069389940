import { AxiosPromise, AxiosResponse, AxiosError } from 'axios'
import { api, apiWithoutHandling } from '@src/api'
import { EntityTypes, API } from '@src/constants/api'
import { GetRequestInterface, Id, RequestInterfaceNew } from '@src/interfaces'
import {
  AccessDashboardRequestInterface,
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardRatingInterface,
  GenericAnalyticsDashboardInterface,
  InternalDashboardInterface,
  TenantDashboardDeploymentInterface,
} from '@src/interfaces/analyticsDashboards'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { UseQueryOptions, useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { useQueryClient, useMutation } from 'react-query'
import { RunQueryResponseInterface, QueryInterface } from '@src/interfaces/dataAnalytics'
import { isQueryRunning } from '@src/pages/Forms/QueryForm/utils'

export const getAnalyticsDashboards =
  (entityType: EntityTypes | 'goal', id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<AnalyticsDashboardInterface>
  > =>
    api.get(`${API.DASHBOARDS}`, {
      params: {
        ...(id && { [`related_${entityType}s`]: id }),
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getAnalyticsDashboardsOrdered =
  (entityType: EntityTypes, id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<GenericAnalyticsDashboardInterface>
  > =>
    api.get(`${API.DASHBOARDS}/ordering`, {
      params: {
        ...(id && { [`related_${entityType}s`]: id }),
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getAllLookerDashboards = (): AxiosPromise<
  GetRequestInterface<AnalyticsDashboardInterface>
> => api.get(`${API.DASHBOARDS}/looker`)

export const useLookerDashboard = (
  id: number | undefined,
  queryOptions?: UseQueryOptions<AnalyticsDashboardInterface>,
) => {
  return useFetch<AnalyticsDashboardInterface>({
    url: id ? `${API.DASHBOARDS}/looker/${id}` : null,
    queryOptions,
  })
}

export const dashboardRequestsNew: RequestInterfaceNew<AnalyticsDashboardInterface> = {
  get: async ({ id }) => api.get(`${API.DASHBOARDS}/looker/${id}`),
  update: async (_, { id }, data) => api.put(`${API.DASHBOARDS}/looker/${id}`, data),
  submit: async data => api.post(`${API.DASHBOARDS}/looker`, data),
  delete: async ({ id }) => api.delete(`${API.DASHBOARDS}/looker/${id}`),
}

export const internalDashboardRequests: RequestInterfaceNew<InternalDashboardInterface> =
  {
    get: async ({ id }) => api.get(`${API.DASHBOARDS}/internal/${id}`),
    update: async (_, { id }, data) => api.put(`${API.DASHBOARDS}/internal/${id}`, data),
    submit: async data => api.post(`${API.DASHBOARDS}/internal`, data),
    delete: async ({ id }) => api.delete(`${API.DASHBOARDS}/internal/${id}`),
  }

export const useGetInternalDashboard = (id?: string) => {
  return useFetch<InternalDashboardInterface>({
    url: id ? `${API.DASHBOARDS}/internal/${id}` : null,
  })
}

export const createRelatedCompany = (dashboardId: number | string) =>
  api.post(`${API.DASHBOARDS}/${dashboardId}/companyRelated`)

export const createRelatedDepartments = (
  dashboardId: number | string,
  departmentId: number,
) => api.post(`${API.DASHBOARDS}/${dashboardId}/relatedDepartments/${departmentId}`)

export const createRelatedTeams = (dashboardId: number | string, teamId: number) =>
  api.post(`${API.DASHBOARDS}/${dashboardId}/relatedTeams/${teamId}`)

export const createRelatedEmployees = (
  dashboardId: number | string,
  employeeId: number,
) => api.post(`${API.DASHBOARDS}/${dashboardId}/relatedEmployees/${employeeId}`)

export const createRelatedGoals = (dashboardId: number | string, goalId: number) =>
  apiWithoutHandling.post(`${API.DASHBOARDS}/${dashboardId}/relatedGoals/${goalId}`)

export const deleteRelatedGoals = (dashboardId: number | string, goalId: number) =>
  apiWithoutHandling.delete(`${API.DASHBOARDS}/${dashboardId}/relatedGoals/${goalId}`)

export const deleteRelatedCompany = (dashboardId: number | string) =>
  api.delete(`${API.DASHBOARDS}/${dashboardId}/companyRelated`)

export const deleteRelatedDepartments = (
  dashboardId: number | string,
  departmentId: number,
) => api.delete(`${API.DASHBOARDS}/${dashboardId}/relatedDepartments/${departmentId}`)

export const deleteRelatedTeams = (dashboardId: number | string, teamId: number) =>
  api.delete(`${API.DASHBOARDS}/${dashboardId}/relatedTeams/${teamId}`)

export const deleteRelatedEmployees = (
  dashboardId: number | string,
  employeeId: number,
) => api.delete(`${API.DASHBOARDS}/${dashboardId}/relatedEmployees/${employeeId}`)

export const activateDashboard = (id: string | number) =>
  api.put<AnalyticsDashboardInterface>(`${API.DASHBOARDS}/${id}/activate`)

export const archiveDashboard = (id: string | number) =>
  api.put<AnalyticsDashboardInterface>(`${API.DASHBOARDS}/${id}/archive`)

export const createDashboardRating = (
  dashboardId: number,
  data: Pick<GenericAnalyticsDashboardRatingInterface, 'score' | 'comment' | 'labels'>,
) => api.post(`${API.DASHBOARDS}/${dashboardId}/ratings`, data)

export const accessDashboardRequest = (
  dashboardId: number,
  data: AccessDashboardRequestInterface,
) =>
  apiWithoutHandling.post(`${API.DASHBOARDS}/looker/${dashboardId}/accessRequests`, data)

export const updateDashboardsOrder = (
  data: UpdateOrderingInterface,
  filters: FilterByInterface[],
) =>
  api.post(`${API.DASHBOARDS}/ordering/itemPositionUpdate`, data, {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const updateDashboard = (diff: Id & Partial<GenericAnalyticsDashboardInterface>) =>
  apiWithoutHandling.patch(`${API.DASHBOARDS}/looker/${diff.id}`, diff)

export const useSaveDashboardDeployments = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<TenantDashboardDeploymentInterface>,
    AxiosError,
    Omit<TenantDashboardDeploymentInterface, 'id'>
  >(data => apiWithoutHandling.post(`${API.TENANTS}/dashboardDeployments`, data), {
    onSuccess: response => {
      if (response.data) {
        queryClient.setQueryData<InternalDashboardInterface>(
          [`${API.DASHBOARDS}/internal/${response.data.dashboard_id}`],
          oldData => ({ ...oldData!, tenant_deployment: response.data }),
        )
      }
    },
  })
}

export const useUpdateDashboardDeployments = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<TenantDashboardDeploymentInterface>,
    AxiosError,
    [string | number, Omit<TenantDashboardDeploymentInterface, 'id'>]
  >(
    ([id, data]) =>
      apiWithoutHandling.put(`${API.TENANTS}/dashboardDeployments/${id}`, data),
    {
      onSuccess: response => {
        if (response.data) {
          queryClient.setQueryData<InternalDashboardInterface>(
            [`${API.DASHBOARDS}/internal/${response.data.dashboard_id}`],
            oldData => ({ ...oldData!, tenant_deployment: response.data }),
          )
        }
      },
    },
  )
}
export const useGetDashboardQueryRunWithInterval = (
  dashboardId?: number | string,
  queryId?: number | string,
  runId?: number,
) =>
  useFetch<RunQueryResponseInterface>({
    url:
      queryId && runId
        ? dashboardId
          ? `${API.DASHBOARDS}/internal/${dashboardId}/queries/${queryId}/runs/${runId}`
          : `${API.REPORTING}/queries/${queryId}/runs/${runId}`
        : null,
    queryOptions: {
      refetchInterval: data => (isQueryRunning(data?.status) ? 1500 : false),
    },
  })

export const useGetDashboardQuery = (
  id: number | string,
  dashboardId?: number | string,
) =>
  useFetch<QueryInterface>({
    url: dashboardId
      ? `${API.DASHBOARDS}/internal/${dashboardId}/queries/${id}`
      : `${API.REPORTING}/queries/${id}`,
    withoutHandling: true,
  })

export const useDashboardQueriesOptions = (
  dashboardId: number | string,
  filtersParams: FilterByInterface[],
) => {
  const result = useFetch<GetRequestInterface<QueryInterface>>(
    `${API.DASHBOARDS}/internal/${dashboardId}/queries`,
    'v1',
    {
      params: {
        page_size: 100000,
        ...filterSortPageIntoQuery(undefined, filtersParams),
      },
    },
    undefined,
    { enabled: !!dashboardId },
  )

  return result.data?.results
}
