import React, { useEffect, useRef } from 'react'
import {
  DeliverableOption,
  DeliverableOptions,
  deliverableOptionToFinalGrade,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getKpiGrades,
  DeliverableGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { VStack } from '@revolut/ui-kit'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { GoalsCardTable } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/ScorecardSetup/GoalsCardTable'
import isNumber from 'lodash/isNumber'
import { TeamGoalsList } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsList'

interface DeliverablesscardInterface extends CommonCardProps {
  showGoals: boolean
  deliverablesMissingJustification?: number[]
  isSegmentedDeliverables?: boolean
}

export const DeliverablesCard = connect(
  ({
    onHelpClick,
    gradesMap,
    showGoals,
    deliverablesMissingJustification,
    isSegmentedDeliverables,
  }: DeliverablesscardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasDeliverables = !!values.review_data.deliverables
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()

    useEffect(() => {
      if (errors.review_data?.deliverables) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.deliverables])

    if (!hasDeliverables) {
      return null
    }

    const deliverablesGrades = getKpiGrades(gradesMap, false)

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.deliverablesGrade
        ? gradesMap[grades?.deliverablesGrade]
        : undefined

    const gradeRecommendation = DeliverableOptions.INTERMEDIATE

    const deliverablesGradesWithExp = getGradesWithExpectations(
      deliverablesGrades,
      gradeRecommendation,
    )

    const onSelectGrade = (
      reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
      grade: DeliverableGradeOption,
      field: CardField,
    ) => {
      if (isSegmentedDeliverables) {
        set(values, `${field.field}.sections.0.value`, grade.key)
        set(values, `${field.field}.rating`, grade.key)
      } else {
        reviewData.deliverables?.cards?.forEach(card => {
          set(card, `sections.0.value`, grade.key)
          set(card, `rating`, grade.key)
        })
        set(
          reviewData,
          'deliverables.section_grade',
          deliverableOptionToFinalGrade(grade.key),
        )
      }
    }

    const getSegmentedDeliverablesGrades = (cardInd: number) => {
      const sectionOptions = get(
        values,
        `review_data.deliverables.cards.${cardInd}.sections.0.options`,
      )
        .filter(
          (option: DeliverableOption) => option.key !== DeliverableOptions.DONT_KNOW,
        )
        .map((option: DeliverableOption) => ({
          ...option,
          text: gradesMap[deliverableOptionToFinalGrade(option.key)],
          description: [option.text],
          tooltip: option.text,
        }))

      return getGradesWithExpectations(sectionOptions, gradeRecommendation)
    }

    const fields = isSegmentedDeliverables
      ? values.review_data.deliverables?.cards?.map((card, ind) => {
          const justification = card.justifications?.[0]
          return {
            field: `review_data.deliverables.cards.${ind}`,
            title: card.name,
            grades: getSegmentedDeliverablesGrades(ind),
            cardJustification:
              justification && 'comment' in justification ? justification.comment : null,
          }
        }) || []
      : [
          {
            field: `review_data.deliverables.cards.0`,
            title: 'Contribution and impact',
            grades: deliverablesGradesWithExp,
            cardJustification:
              values.review_data.deliverables?.justifications?.[0]?.comment || null,
          },
        ]

    return (
      <Card
        data={values}
        renderExpandedContent={expContentField => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={
              isSegmentedDeliverables
                ? getSegmentedDeliverablesGrades(expContentField.cardIndex || 0)
                : deliverablesGradesWithExp
            }
            onSelectGrade={(reviewData, grade) =>
              onSelectGrade(reviewData, grade, expContentField.field)
            }
            gradesMap={gradesMap}
            selectedField={expContentField}
            isSegmentedDeliverables={!!isSegmentedDeliverables}
          />
        )}
        renderExceedingContent={excContentField => {
          const justificationComment = get(
            values,
            `review_data.deliverables.justifications.${excContentField.cardIndex}.comment`,
          )
          return (
            <Deliverables
              reviewData={values.review_data}
              deliverablesGrades={deliverablesGradesWithExp}
              showJustificationError={
                !justificationComment &&
                isNumber(excContentField.cardIndex) &&
                !!deliverablesMissingJustification?.includes(excContentField.cardIndex)
              }
              justificationOnly
              gradesMap={gradesMap}
              selectedField={excContentField}
              isSegmentedDeliverables={!!isSegmentedDeliverables}
            />
          )
        }}
        additionalInfo={
          isSegmentedDeliverables ? undefined : (
            <VStack space="s-16">
              <NoPersonalGoalsBanner team={values.team} />
              {showGoals ? (
                <GoalsCardTable cycle={values.cycle} />
              ) : (
                <TeamGoalsList
                  gradesMap={gradesMap}
                  cycle={values.cycle}
                  team={values.team}
                />
              )}
            </VStack>
          )
        }
        type={CardContentTypes.DELIVERABLES}
        title={isSegmentedDeliverables ? 'Deliverables' : 'Goals'}
        finalRating={finalRating}
        icon="Target"
        fields={fields}
        onSelectDeliverableGrade={(grade, field) =>
          onSelectGrade(values.review_data, grade, field)
        }
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.sections?.[0]?.value
          return !!ratingValue && ratingValue === grade.key
        }}
        justification={values?.review_data?.deliverables?.skipped_section_justification}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
