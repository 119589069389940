import { VisuallyHidden } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import {
  EpicOption,
  KpiInterface,
  KpiTargetEpics,
  UpdateTypes,
} from '@src/interfaces/kpis'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import React, { useMemo } from 'react'
import { TargetFormFields } from '../SidebarForms/TargetFormFields'
import toString from 'lodash/toString'

export const TargetFieldsByType = ({
  type,
  cycle,
  variant = 'new',
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
  variant?: 'new' | 'side-edit'
}) => {
  const form = useLapeContext<KpiInterface>()
  const { values } = form
  const jiraEpicsField = useLapeField('target_epics.0.epics')

  const epics = useMemo(() => {
    return values.target_epics?.length
      ? values.target_epics[0].epics.reduce((acc, epicOption) => {
          if (epicOption.key) {
            return {
              ...acc,
              [epicOption.key]: {
                display_name: epicOption.name,
                epic_name: epicOption.name,
                epic_url: epicOption.url,
                id: epicOption.id,
                issue_type: 'Epic', // only epics could be selected
                key: epicOption.key,
              },
            }
          }
          return acc
        }, {})
      : undefined
  }, [values.target_epics?.length])

  switch (type) {
    case UpdateTypes.roadmap:
      return (
        <>
          <VisuallyHidden>
            <LapeNewInput name="target_epics.0.epics" />
          </VisuallyHidden>
          <JiraSearchWidget
            description={jiraEpicsField?.error && toString(jiraEpicsField.error)}
            invalid={!!jiraEpicsField?.error}
            forceSelected={epics}
            onSelectionChange={selected => {
              if (!values.target_epics) {
                values.target_epics = []
              }

              const target: KpiTargetEpics = values.target_epics[0] || {}
              target.epics = Object.values(selected).map(
                epic =>
                  ({
                    key: epic.key,
                    name: epic.epic_name,
                    owner: epic.owner,
                    url: epic.epic_url,
                    review_cycle:
                      cycle?.category === ReviewCycleCategory.Performance
                        ? cycle
                        : undefined,
                  } as EpicOption),
              )
              values.target_epics[0] = target
            }}
          />
        </>
      )

    case UpdateTypes.sql:
    case UpdateTypes.manual:
    case UpdateTypes.looker:
      return (
        <TargetFormFields
          variant={variant}
          hideLooker
          codeEditorProps={{
            responsive: true,
            width: '100%',
            height: 350,
            wrapResponsive: true,
          }}
        />
      )
    default:
      return null
  }
}
