import React from 'react'
import { Avatar, IconButton, Item, Token, Icon, IconName } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetContractsSettings, useGetEmployeeSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { InactivityWarning } from '../../Preview/components/Buttons/ChangeStatusDropdown/SetInactive/InactivityWarning'
import { TerminationDateRequiredWarning } from '../../Preview/components/TerminationDateRequiredWarning'
import { OnboardingWarning } from '../../Preview/ProfileSummary/components/OnboardingWarning'
import { AnonymizedWarning } from '../../Preview/components/AnonymizedWarning'
import {
  canTerminateEmployees,
  canViewChangelog,
  canViewContracts,
  canViewKeyPerson,
  canViewOwnership,
  canViewPersonal,
  canViewRightToWork,
  canViewTermination,
} from '../../Preview/ProfileSummary/common'
import { LayoutTabProps } from '../common/types'
import { TwoColumnsLayout } from '../common/TwoColumnsLayout'
import { WorkDetails } from './WorkDetails'
import { Contracts } from './Contracts'
import { PersonalDetails } from './PersonalDetails'
import { Badges } from './Badges'
import { Reports } from './Reports'
import { TerminationWidget } from './Termination'
import { RightToWork } from './RightToWork'

type NavigationItemProps = {
  title: string
  path: string
  icon: IconName
  params: Object
}
const NavigationItem = ({ title, path, icon, params }: NavigationItemProps) => (
  <Item use={InternalLink} to={pathToUrl(path, params)}>
    <Item.Avatar>
      <Avatar useIcon={<Icon name={icon} />} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
    </Item.Content>
    <Item.Side>
      <IconButton useIcon={<Icon name="ChevronRight" />} color={Token.color.greyTone20} />
    </Item.Side>
  </Item>
)

export const SummaryLayoutTab = ({
  data,
  primaryDataHandler,
  dynamicGroups,
  sectionsData,
  statusTransitions,
  employeeInactivity,
}: LayoutTabProps) => {
  const { data: employeeSettings } = useGetEmployeeSettings()
  const { data: contractsSettings } = useGetContractsSettings()

  const isCommercial = useIsCommercial()

  const showOnboardingWarning = ['onboarding', 'hired', 'not_hired', 'pending'].includes(
    data.status.id,
  )
  const showTerminationDateRequiredWarning =
    canTerminateEmployees(data) &&
    data.employee_type === 'external' &&
    !data.has_termination &&
    contractsSettings?.force_termination_enabled

  const showTermination = canViewTermination(data) && data.has_termination

  return (
    <>
      <TwoColumnsLayout
        left={
          <>
            <AnonymizedWarning employee={data} />
            <InactivityWarning
              employee={data}
              employeeInactivity={employeeInactivity}
              statusTransitions={statusTransitions}
            />
            {showTerminationDateRequiredWarning && (
              <TerminationDateRequiredWarning data={data} />
            )}
            {showOnboardingWarning && <OnboardingWarning data={data} />}
            {showTermination && <TerminationWidget data={data} />}
            <WorkDetails
              data={data}
              primaryDataHandler={primaryDataHandler}
              dynamicGroups={dynamicGroups}
              sectionsData={sectionsData}
            />
            {canViewContracts(data) && <Contracts data={data} />}
            {canViewPersonal(data) && (
              <PersonalDetails data={data} dynamicGroups={dynamicGroups} />
            )}
            {canViewChangelog(data) && (
              <NavigationItem
                title="Changelog"
                path={ROUTES.FORMS.EMPLOYEE.CHANGELOG}
                icon="16/ListBullet"
                params={{ id: String(data.id) }}
              />
            )}
          </>
        }
        right={
          <>
            {employeeSettings?.enable_dynamic_groups_badges && <Badges data={data} />}
            <Reports data={data} />
            {canViewRightToWork(data) && <RightToWork data={data} />}
            {canViewKeyPerson(data, !isCommercial) && (
              <NavigationItem
                title="Key persons"
                path={ROUTES.FORMS.EMPLOYEE.KEY_PERSON}
                icon="Gift"
                params={{ id: String(data.id) }}
              />
            )}
            {canViewOwnership(data) && (
              <NavigationItem
                title="Ownership"
                path={ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL}
                icon="Profile"
                params={{ id: String(data.id) }}
              />
            )}
          </>
        }
      />
    </>
  )
}
