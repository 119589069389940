import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { PayGroupListItemInterface } from '@src/interfaces/payrollV2'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api } from '.'

export const payGroupTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayGroupListItemInterface>>(
      `${API.PAYROLL}/payGroups`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
}
