import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
  InterviewResultToTitle,
} from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import { ActionButton, HStack, Text, Token } from '@revolut/ui-kit'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import Tooltip from '@components/Tooltip/Tooltip'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import Table from '@components/TableV2/Table'
import { useIsNewTable } from '@components/TableV2/hooks'

const ViewLink = styled(Link)`
  color: ${Token.color.blue};
  text-decoration: none;

  &:hover {
    color: ${Token.color.blue_60};
  }
`

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  justify-content: flex-start;
`

const DisabledLink = styled.span`
  color: ${Token.color.blue};
  cursor: not-allowed;
  opacity: 0.5;
`

export const interviewStatus: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status_order',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <Table.StatusCell status={{ id: data.status, name: capitalize(data.status) }} />
  ),
  title: 'Status',
}

export const interviewCandidate: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.interview_round.candidate.full_name',
  dataPoint: 'interview_stage.interview_round.candidate.full_name',
  sortKey: 'interview_stage.interview_round.candidate.full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate',
}

export const interviewRole: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.interview_round.role.id',
  dataPoint: 'interview_stage.interview_round.role.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
}

export const interviewSeniority: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'interview_stage.interview_round.seniority.id',
  dataPoint: 'interview_stage.interview_round.seniority.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <Table.SeniorityCell
      seniority={data.interview_stage?.interview_round?.seniority?.name}
    />
  ),
}

export const interviewNameColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.title',
  dataPoint: 'interview_stage.title',
  sortKey: 'interview_stage__title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
}

export const interviewDateColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'scheduled_date_time',
  dataPoint: 'scheduled_date_time',
  sortKey: 'scheduled_date_time',
  filterKey: 'scheduled_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Date',
}

export const interviewRatingColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'result',
  dataPoint: 'result',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.status === InterviewFeedbackStatus.completed ? (
      <HStack gap="s-4">
        <InterviewResultIcon
          result={data.result}
          status={data.status}
          noColor
          iconSize={16}
        />
        {data.result && <Text>{InterviewResultToTitle[data.result]}</Text>}
      </HStack>
    ) : (
      '-'
    ),
  title: 'Rating',
}

const ScorecardButton = ({
  id,
  status,
  editable,
}: {
  id: number
  status: InterviewFeedbackStatus
  editable?: boolean
}) => {
  const isNewTable = useIsNewTable()

  let label = null

  switch (status) {
    case InterviewFeedbackStatus.completed:
      label = 'Edit'
      break

    case InterviewFeedbackStatus.pending:
      label = 'Rate'
      break

    default:
      label = 'View'
  }

  if (isNewTable) {
    if (!editable) {
      return (
        <StyledTooltip placement="top" text="You are not allowed to edit previous stage">
          <ActionButton onClick={e => e.stopPropagation()} variant="primary" disabled>
            {label}
          </ActionButton>
        </StyledTooltip>
      )
    }

    return (
      <ActionButton
        use={Link}
        // @ts-expect-error object works fine here, but UI kit expects string
        to={getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id }),
        )}
        onClick={e => e.stopPropagation()}
        variant="primary"
      >
        {label}
      </ActionButton>
    )
  }

  if (!editable) {
    return (
      <StyledTooltip placement="top" text="You are not allowed to edit previous stage">
        <DisabledLink onClick={e => e.stopPropagation()}>{label}</DisabledLink>
      </StyledTooltip>
    )
  }

  return (
    <ViewLink
      to={getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id }),
      )}
      onClick={e => e.stopPropagation()}
    >
      {label}
    </ViewLink>
  )
}

export const interviewScorecardColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Scorecard',
  insert: ({ data }) => (
    <ScorecardButton
      id={data.id}
      status={data.status}
      editable={data.is_feedback_editable}
    />
  ),
}
