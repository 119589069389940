import React from 'react'
import { AxiosPromise } from 'axios'
import { scaleLinear } from 'd3-scale'
import { curveStepAfter, line } from 'd3-shape'
import { Token } from '@revolut/ui-kit'
import PopupChart from '../BaseChart/PopupChart'
import { PerformanceChartCycles, TargetInterface } from '@src/interfaces/chart'
import { PerformanceTimeRange } from '@src/constants/api'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import {
  FinalGradeNumberToGrade,
  PerformanceTimelineChartGradesData,
  PerformanceTimelineChartSingleData,
} from '@src/interfaces/performance'
import styled from 'styled-components'
import {
  FinalGrade,
  FinalGradeToGraphNumber,
  PerformanceRating,
  performanceRatingFromNumber,
  PerformanceRatingToGraphNumber,
} from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { FilterByInterface } from '@src/interfaces/data'
import { getCalibrationFilters } from '@src/features/Calibration/getCalibrationFilters'
import { useGetReviewGradesMap } from '@src/utils/grades'
import isObject from 'lodash/isObject'
import {
  LegendItem,
  LegendTargetItem,
  LegendWrapper,
  Square,
} from '@components/Charts/BaseChart/Legend'

const TooltipContainer = styled.div`
  color: ${Token.color.background};
`

export type fetchKpiType = (
  id: string,
  period: PerformanceTimeRange,
  cycleOffset?: string,
) => AxiosPromise<PerformanceChartCycles>

interface Props {
  data?: PerformanceTimelineChartSingleData | PerformanceTimelineChartGradesData
  id: number
  isRating?: boolean
  children: React.ReactNode
  vertical?: 'left' | 'right'
  fetchKpi?: (filters: FilterByInterface[]) => fetchKpiType
  disabled?: boolean
}

const expectedLayer =
  (_targets: TargetInterface[], _currency?: string) =>
  ({ bars, width, xScale, yScale }: any) => {
    if (!bars?.length) {
      return null
    }

    // add an extra data point to extend the line beyond the final data point
    const extraBars = bars.concat(bars[bars.length - 1])
    const maxIndex = extraBars.length - 1
    const xCustomScale = scaleLinear().domain([0, maxIndex]).range([0, width])

    let endX = xCustomScale(0)

    const getEndX = (bar: any, index: number) => {
      if (index !== 0 && index !== maxIndex) {
        endX = xScale(bar.data.indexValue) - bar.width / 2
      }

      if (index === maxIndex) {
        endX = xCustomScale(maxIndex)
      }

      return endX
    }

    const lineGenerator = line<any>()
      .curve(curveStepAfter)
      .x(getEndX)
      .y(bar => yScale(bar.data.data.expected || 0))

    return (
      <path
        d={lineGenerator(extraBars) || ''}
        fill="none"
        stroke={Token.color.red}
        strokeDasharray="1%"
        strokeLinecap="round"
        strokeWidth="2"
        style={{ pointerEvents: 'none' }}
      />
    )
  }

const formatValue = (value: string | number): string =>
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  PerformanceRatingTitle[performanceRatingFromNumber.get(+value) || '']

export const Chart = ({
  data,
  id,
  isRating = false,
  fetchKpi,
}: Omit<Props, 'children' | 'vertical'>) => {
  const { entity } = useOrgEntity()
  const { gradesMap } = useGetReviewGradesMap()

  const layers = [expectedLayer]

  return (
    <BaseChartInner
      id={id}
      data={data}
      fetchData={fetchKpi ? fetchKpi(getCalibrationFilters(entity)) : undefined}
      graphTimeRange={PerformanceTimeRange.quarter}
      axisLeft={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        format: (d: any) => {
          return isRating ? formatValue(d) : gradesMap[FinalGradeNumberToGrade[d]] || ''
        },
        tickValues: isRating ? [0, 1, 4, 7, 10, 13] : undefined,
      }}
      gridYValues={isRating ? [...performanceRatingFromNumber.keys()] : [1, 2, 3, 4, 5]}
      margin={{ top: 10, right: 110, bottom: 75, left: 20 }}
      findMaxValue={() =>
        isRating
          ? PerformanceRatingToGraphNumber[PerformanceRating.expert_plus]
          : FinalGradeToGraphNumber[FinalGrade.Exceptional]
      }
      tooltip={({ value, indexValue }) => {
        const gradeProgress = data?.values
          ? [...data.values].find(item => item.progress_datetime_label === indexValue)
              ?.progress
          : undefined
        const gradeLabel = isObject(gradeProgress) ? gradeProgress?.label : gradeProgress

        return (
          <TooltipContainer>
            {isRating
              ? formatValue(value)
              : gradeLabel || gradesMap[FinalGradeNumberToGrade[value]]}
          </TooltipContainer>
        )
      }}
      indexBy="progress_datetime_label"
      showTargetLegend
      layers={layers}
      colors={bar => {
        return bar?.data?.is_deprecated_grade
          ? [Token.color.deepGrey]
          : [Token.color.blue]
      }}
      legend={
        <LegendWrapper>
          {!isRating && (
            <LegendItem>
              <Square color={Token.color.deepGrey} />
              Prior grade system
            </LegendItem>
          )}
          <LegendItem>
            <Square color={Token.color.blue} />
            {isRating ? 'Historical' : 'Current grade system'}
          </LegendItem>
          <LegendTargetItem title="Expected" color={Token.color.red_70} />
        </LegendWrapper>
      }
    />
  )
}

const EmployeePerformanceChart = ({
  data,
  id,
  isRating,
  children,
  fetchKpi,
  vertical,
  disabled,
}: Props) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <PopupChart trigger={children} vertical={vertical}>
      {() => <Chart data={data} id={id} isRating={isRating} fetchKpi={fetchKpi} />}
    </PopupChart>
  )
}

export default EmployeePerformanceChart
