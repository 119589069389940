import React from 'react'

import { Tag, Ellipsis, Text } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'

type Props = {
  seniority?: string
}

export const SeniorityCell = ({ seniority }: Props) => {
  const isNewTable = useIsNewTable()

  if (!seniority) {
    return <>-</>
  }

  if (!isNewTable) {
    return <Text>{seniority}</Text>
  }

  return (
    <Tag variant="faded" style={{ maxWidth: '100%' }}>
      <Ellipsis>{seniority}</Ellipsis>
    </Tag>
  )
}
