import { Token } from '@revolut/ui-kit'
import { OnboardingTemplateStatus, OnboardingStatus } from '@src/interfaces/onboardingV2'

export const getStatusColor = (status: OnboardingStatus) => {
  switch (status) {
    case OnboardingStatus.cancelled:
      return Token.color.red
    case OnboardingStatus.completed:
      return Token.color.green
    case OnboardingStatus.in_progress:
      return Token.color.orange
    default:
      return Token.color.greyTone50
  }
}

export const getTemplateStatusColor = (status: OnboardingTemplateStatus) => {
  switch (status) {
    case OnboardingTemplateStatus.pending_approval:
    case OnboardingTemplateStatus.pending_request:
      return Token.color.blue
    case OnboardingTemplateStatus.pending_signature:
    case OnboardingTemplateStatus.pending_upload:
      return Token.color.orange
    case OnboardingTemplateStatus.rejected:
      return Token.color.red
    case OnboardingTemplateStatus.completed:
      return Token.color.green
    default:
      return Token.color.greyTone50
  }
}

export const getTemplateIcon = (status: OnboardingTemplateStatus) => {
  switch (status) {
    case OnboardingTemplateStatus.pending_approval:
    case OnboardingTemplateStatus.pending_request:
      return 'Bell'
    case OnboardingTemplateStatus.pending_signature:
    case OnboardingTemplateStatus.pending_upload:
      return '16/StatusClockArrows'
    case OnboardingTemplateStatus.rejected:
      return 'Cross'
    default:
      return 'Check'
  }
}

export const getTaskColor = (status: OnboardingStatus) => {
  switch (status) {
    case OnboardingStatus.cancelled:
      return Token.color.red
    case OnboardingStatus.completed:
      return Token.color.green
    case OnboardingStatus.in_progress:
    case OnboardingStatus.not_started:
      return Token.color.orange
    default:
      return Token.color.greyTone50
  }
}

export const getTaskIcon = (status: OnboardingStatus) => {
  switch (status) {
    case OnboardingStatus.in_progress:
      return 'Bell'
    case OnboardingStatus.cancelled:
      return 'Cross'
    default:
      return 'Check'
  }
}
