import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  ApproveKPICategoryExtra,
  SetKPICategoryExtra,
} from '@src/interfaces/kpiNotifications'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AccessRequestType } from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { getChangeRequestDetails } from '@src/api/employeeChangeRequest'

export const linkNotificationToForm = async (
  notification: NotificationsInterface,
  isNewLayout?: boolean,
  isNewScorecards?: boolean,
) => {
  switch (notification.category) {
    case 'kpi_to_assign':
      if (notification.category_extra === SetKPICategoryExtra.Employee) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.PERSONAL, {
            id: notification.generated_by?.id,
            notificationId: notification.item_id,
          }),
        )
      }
      if (notification.category_extra === SetKPICategoryExtra.Team) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.TEAM, {
            id: notification.item_uuid,
            notificationId: notification.item_id,
          }),
        )
      }
      return navigateTo(
        pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.DEPARTMENT, {
          id: notification.item_uuid,
          notificationId: notification.item_id,
        }),
      )
    case 'kpi_to_approve':
      if (notification.category_extra === ApproveKPICategoryExtra.Team) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.APPROVE_KPIS.TEAM, {
            id: notification.item_uuid,
            notificationId: notification.item_id,
          }),
        )
      }
      return navigateTo(
        pathToUrl(ROUTES.FORMS.APPROVE_KPIS.DEPARTMENT, {
          id: notification.item_uuid,
          notificationId: notification.item_id,
        }),
      )
    case 'kpi_bulk_upload':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.IMPORT_DATA.KPIS.STATUS, { id: notification.item_id }),
      )
    case 'requisition':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: notification.item_id }),
      )
    case 'role':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id: notification.item_id }),
      )
    case 'time_off':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
          id: notification.item_id,
          employeeId: notification.generated_by?.id,
        }),
      )
    case 'kpi':
      return navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: notification.item_id }))
    case 'skill':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id: notification.item_id }),
      )
    case 'pending_employee_review':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: notification.item_id }),
      )
    case 'finding':
    case 'service_desk':
      return window.open(notification.text, '__blank', 'noopener noreferrer')
    case 'probation':
      if (notification.category_extra === 'probation_v2') {
        return navigateTo(
          `${pathToUrl(
            isNewScorecards
              ? ROUTES.FORMS.PROBATION_REVIEW_LAYOUT
              : ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES,
            {
              employeeId: notification?.generated_by?.id,
              id: notification.item_id,
            },
          )}`,
        )
      }
      if (notification.category_extra === 'probation_v2_recommendation') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PROBATION_FINAL_RESULT.GENERAL, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
            id: notification.item_id,
          })}`,
        )
      }
      if (notification.category_extra === 'probation_v2_decision') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
          })}`,
        )
      }
      if (notification.category_extra === 'probation_v2_goals') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
          })}`,
        )
      }
      if (notification.category_extra === 'probation_goals_approval') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
          })}`,
        )
      }
      return navigateTo(
        `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: notification?.generated_by?.id,
        })}?cycle_id=${notification.cycle_id}&request_id=${notification.item_id}`,
      )
    case 'pip':
      if (notification.category_extra === 'pip_review') {
        return navigateTo(
          `${pathToUrl(
            isNewScorecards
              ? ROUTES.FORMS.PIP_REVIEW_LAYOUT
              : ROUTES.FORMS.PIP_REVIEW.DELIVERABLES,
            {
              employeeId: notification?.generated_by?.id,
              id: notification.item_id,
            },
          )}`,
        )
      }
      if (notification.category_extra === 'pip_goals_add') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
          })}`,
        )
      }
      if (notification.category_extra === 'pip_goals_approval') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS, {
            employeeId: notification?.generated_by?.id,
            cycleId: notification.cycle_id,
          })}`,
        )
      }
      if (notification.category_extra === 'pip_requested_edits_on_goals') {
        return navigateTo(
          `${pathToUrl(ROUTES.FORMS.PIP.GOAL_SETTINGS, {
            employeeId: notification?.generated_by?.id,
            id: notification.cycle_id,
          })}`,
        )
      }
      return isNewLayout
        ? navigateTo(
            `${pathToUrl(
              ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.SUMMARY,
              {
                id: notification?.generated_by?.id,
              },
            )}?cycle_id=${notification.cycle_id}`,
          )
        : navigateTo(
            `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
              id: notification?.generated_by?.id,
            })}?cycle_id=${notification.cycle_id}&request_id=${notification.item_id}`,
          )

    case 'upwards_review':
      return navigateTo(
        pathToUrl(
          isNewLayout
            ? ROUTES.FORMS.EMPLOYEE.UPWARDS
            : ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS,
          {
            id: notification?.generated_by?.id,
          },
        ),
      )
    case 'review':
      return navigateTo(
        pathToUrl(
          isNewScorecards
            ? ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT
            : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL,
          {
            id: notification.item_id,
            employeeId: notification?.generated_by?.id,
          },
        ),
      )
    case 'document':
      if (notification.category_extra === 'embedded') {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.DOCUMENT_EMBEDDED.GENERAL, {
            employeeId: notification.receiver_id,
            id: notification.item_id,
          }),
        )
      }
      if (notification.category_extra === 'docusign') {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.DOCUMENT_DOCUSIGN.GENERAL, {
            employeeId: notification.receiver_id,
            id: notification.item_uuid,
          }),
        )
      }
      if (notification.category_extra === 'document_approval') {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
            id: notification.item_id,
            employeeId: notification.generated_by?.id,
          }),
        )
      }
      return navigateTo(
        pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
          id: notification.item_id,
          employeeId: notification.receiver_id,
        }),
      )
    case 'data_access':
      if (notification.category_extra === AccessRequestType.Team) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, {
            id: notification.item_id,
            teamId: notification.item_uuid,
          }),
        )
      }
      if (notification.category_extra === AccessRequestType.LookerFolder) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_FOLDER, {
            id: notification.item_id,
            employeeId: notification?.generated_by?.id,
          }),
        )
      }
      if (notification.category_extra === AccessRequestType.LookerModel) {
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL, {
            id: notification.item_id,
            employeeId: notification?.generated_by?.id,
          }),
        )
      }

      return navigateTo(
        pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.GENERAL, {
          id: notification.item_id,
          employeeId: notification?.generated_by?.id,
        }),
      )
    case 'deliverable_review':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.DELIVERABLES, {
          id: notification.item_id,
        }),
      )
    case 'interview':
      return navigateTo(
        `${pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
          id: notification.item_id,
        })}`,
      )
    case 'candidate':
      return navigateTo(
        `${pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: notification.item_id,
        })}`,
      )
    case 'adhoc_interview':
      return navigateTo(
        `${pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: notification.candidate?.id,
        })}`,
      )
    case 'succession':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES, {
          id: notification.receiver_id,
        }),
      )
    case 'ownership_transfer':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL, {
          id: notification.generated_by?.id,
        }),
      )
    case 'movers':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL, {
          id: notification.generated_by?.id,
        }),
      )
    case 'change_request': {
      if (notification.action_url) {
        const response = await getChangeRequestDetails(notification.action_url)

        return navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
            employeeId: response.data.change_request_set.employee.id,
            id: response.data.change_request_set.id,
          }),
        )
      }
      return null
    }
    case 'group':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, {
          id: notification.item_id,
        }),
      )
    case 'succession_plans':
      return navigateTo(
        pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
          id: notification.item_id,
        }),
      )
    case 'bulk_upload':
      return navigateTo(
        pathToUrl(ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.SESSION, {
          id: notification.item_id,
        }),
      )
    case 'comment': {
      if (!notification.action_url) {
        return null
      }
      return window.open(
        `${notification.action_url}?comment=${notification.item_id}`,
        '_blank',
      )
    }
    case 'employee_onboarding_tasks': {
      if (!notification.action_url) {
        return null
      }

      return window.open(`${notification.action_url}/${notification.item_id}`, '_blank')
    }
    default:
      return notification.action_url
        ? window.open(notification.action_url, '_blank')
        : null
  }
}
