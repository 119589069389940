import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import LinkedAccounts from '@src/pages/EmployeeProfile/Forms/LinkedAccounts/LinkedAccount'
import { PageHeader } from '@components/Page/Header/PageHeader'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

import EmployeeBio from './Bio'
import EmployeeContactInfo from './ContactInfo'
import EmployeeEmergencyContacts from './EmergencyContacts'
import EmployeeLaptopDelivery from './LaptopDelivery'
import EmployeeIdentification from './Identification'
import EmployeeCorrespondenceAddress from './CorrespondenceAddress'
import EmployeeAbout from './About'
import EmployeeContractsTable from './ContractsTable'
import { Screening as EmployeeScreening } from './Screening'
import EmployeePayroll from './BankDetails'
import { KeyPersonResponsibilities } from './KeyPersonResponsibilities'
import { Changelog } from './KeyPersonResponsibilities/Changelog'
import EmployeeDiversity from './Diversity'
import LegacyEmployeeTermination from './Termination/LegacyGeneral'
import ChangePassword from './ChangePassword/ChangePassword'
import EmployeeTermination from './Termination/General'
import { RightToWork as EmployeeRightToWork } from './RightToWork'
import EmployeeName from './Name'
import Position from './Position'
import Organisation from './Organisation'
import Notes from './Notes'
import EmployeePip from './Pip'
import {
  canViewContracts,
  canViewDiversity,
  canViewInbox,
  canViewKeyPerson,
  canViewLinkedAccounts,
  canViewPayroll,
  canViewPersonal,
  canViewRightToWork,
  canViewScreening,
  canViewTermination,
  hasOffboardingV2Enabled,
} from '../Preview/ProfileSummary/common'
import { TerminationProps } from './Termination/General'
import ActionItems from './ActionItems'
import {
  useGetEmployeeSettings,
  useGetScreeningSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { selectUser } from '@src/store/auth/selectors'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import useIsCommercial from '@src/hooks/useIsCommercial'

export interface EmployeeGeneralProps extends TerminationProps {
  data: EmployeeInterface
  dynamicGroups: DynamicGroupIDs
  refreshData: () => void
}

export const General = (props: EmployeeGeneralProps) => {
  const { data } = props
  const params = useParams<{ id: string }>()
  const { data: employeeSettings } = useGetEmployeeSettings()
  const { settings: globalSettings } = useGlobalSettings()
  const { data: screeningSettings } = useGetScreeningSettings()

  const user = useSelector(selectUser)
  const isCommercial = useIsCommercial()
  const isThisUser = data?.id && data.id === user?.id

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params)

  const routes = [
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.NAME,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NAME, params),
      title: 'Name',
      component: EmployeeName,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION, params),
      title: 'Position',
      component: Position,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION, params),
      title: 'Organisation',
      component: Organisation,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES, params),
      title: 'Notes',
      component: Notes,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.BIO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BIO, params),
      title: 'Bio',
      component: EmployeeBio,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO, params),
      title: 'Contact info',
      component: EmployeeContactInfo,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS, params),
      title: 'Emergency contact',
      component: EmployeeEmergencyContacts,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY, params),
      title: 'Laptop delivery',
      component: EmployeeLaptopDelivery,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION, params),
      title: 'Identification details',
      component: EmployeeIdentification,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS, params),
      title: 'Correspondence address',
      component: EmployeeCorrespondenceAddress,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT, params),
      title: 'About me',
      component: EmployeeAbout,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, params),
      title: 'Contracts',
      component: EmployeeContractsTable,
      visibilityCheck: canViewContracts(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, params),
      title: 'Right To Work',
      component: EmployeeRightToWork,
      visibilityCheck: canViewRightToWork(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING, params),
      title: 'Screening',
      component: EmployeeScreening,
      visibilityCheck: canViewScreening(data, !!screeningSettings?.enabled),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS, params),
      title: 'Bank details',
      component: EmployeePayroll,
      visibilityCheck: canViewPayroll(data, !!employeeSettings?.enable_bank_details),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES, params),
      title: 'Responsibilities',
      component: KeyPersonResponsibilities,
      visibilityCheck: canViewKeyPerson(data, !isCommercial),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
        params,
      ),
      title: 'Responsibilities',
      component: Changelog,
      visibilityCheck: canViewKeyPerson(data, !isCommercial),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY, params),
      title: 'Diversity',
      component: EmployeeDiversity,
      visibilityCheck: canViewDiversity(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION, params),
      title: 'Employee termination',
      component: LegacyEmployeeTermination,
      visibilityCheck: canViewTermination(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD, params),
      title: 'Change password',
      component: ChangePassword,
      visibilityCheck: isThisUser,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, params),
      title: 'Employee termination',
      component: EmployeeTermination,
      visibilityCheck: canViewTermination(data) && hasOffboardingV2Enabled(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.PIP,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.PIP, params),
      title: 'Start PIP',
      component: EmployeePip,
      visibilityCheck: true,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.LINKED_ACCOUNTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LINKED_ACCOUNTS, params),
      title: 'Linked Accounts',
      component: LinkedAccounts,
      visibilityCheck: canViewLinkedAccounts(
        data,
        !!globalSettings.linked_accounts_enabled,
      ),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS, params),
      title: 'Action items',
      component: ActionItems,
      visibilityCheck: canViewInbox(data),
    },
  ]

  const filteredRoutes = routes
    .filter(route => (route.visibilityCheck !== undefined ? route.visibilityCheck : true))
    .map(({ visibilityCheck, ...tab }) => tab)

  return (
    <Flex flexDirection="column" flex={1} minHeight={0}>
      <Switch>
        {filteredRoutes.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <PageHeader
              title={tab.title}
              subtitle={
                <UserWithAvatar {...data} status={data.status.id as IdStatuses} asText />
              }
              backUrl={backUrl}
            />

            <tab.component {...props} />
          </Route>
        ))}
        <InternalRedirect to={backUrl} />
      </Switch>
    </Flex>
  )
}
